.page-cases {
  main {
    padding: 80px 20px 0px 20px;

    width: 80%;
    max-width: 800px;
    margin: 3em auto;
    .fat-link {
      color: #1414f0;
      font-weight: 400;
    }

    article {
      margin: 2.5em 0;
    }

    h1 {
      text-align: center;
    }

    h2 {
      margin: 0 0 0.4em;
    }

    h1,
    h2,
    h3 {
      line-height: 1.25em;
    }

    img {
      width: 200px;
      height: auto;
    }
    article {
      > img {
        display: block;
        margin: 0 auto;
      }

      a {
        text-align: center;
      }
    }

    a {
      display: block;
      color: unset;
      text-decoration: none;

      .case-title {
        color: $brand-color;
      }

      &:hover {
        opacity: 0.9;
      }
    }
    img[src*="sigillet"] {
      width: 175px;
    }
    img[src*="akersberga"] {
      width: 275px;
    }

    @include for-phone-only {
      max-width: unset;
      margin: 2em 0 0;
    }
  }
  @media (max-width: 1024px) {
    padding-top: 0px;
  }
}
