.e-sign-api-page {
  .button {
    margin-top: 30px;
  }

  * {
    max-width: 100vw;
  }

  p {
    width: 100%;
    margin: 14px auto;
    padding: 0px 30px;
  }

  h2 {
    padding: 0px 30px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .flex {
    display: flex;
    justify-content: center;
  }

  .main-section {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    h2 {
      text-align: center;
    }

    .button {
      display: block;
      margin-top: 0px;
      margin-bottom: 150px;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
      outline: none;
    }
    p {
      margin: 14px auto;
    }

    .demo-form {
      button {
        width: 150px;
        font-size: 1.2em;
        margin: 0px;
        margin-top: 20px;
        margin-left: 5px;
        padding: 10px 30px;
      }
      p {
        width: 90%;
        padding: 0px;
      }
    }
  }

  .main-section-text {
    height: 252px;
  }

  .all-needs-section {
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    h2 {
      margin-top: 0px;
    }
    .button {
      margin-top: 50px;
      width: 200px;
    }
    .flex {
      p {
        font-size: 14px;
      }
      strong {
        font-size: 16px;
      }
    }
    .check-mark {
      color: white;
      background-color: #1414f0;
      text-align: center;
      border-radius: 50%;
      width: 40px;
      padding: 5px;
      margin: 40px auto;
      margin-left: 38%;
      margin-bottom: 40px;
    }
  }

  .developer-section {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    .button {
      width: 200px;
      display: block;
      margin: 50px auto;
    }
  }

  .customer-case {
    max-width: 1200px;
    margin-bottom: 50px;
    p {
      max-width: none;
    }
    margin-top: 0px;
    padding-top: 0px;

    strong {
      font-size: 1.5em;
    }
    .nameAndTitleWrapper {
      p {
        margin: 5px;
        margin-left: 0px;
        margin-right: 0px;
      }
      strong {
        font-size: 1em;
      }

      margin-bottom: 50px;
    }

    .button {
      display: block;
      width: 200px;
      margin-left: 0px;
    }
    img {
      display: block;
      width: 100%;
      max-width: 500px;
      margin: 0px 30px 0px;
      margin-bottom: 30px;
    }
  }

  .contact-section {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 800px;
    max-width: none;
    margin: 0px;
    padding-top: 45px;
    padding-bottom: 0px;
    width: 100%;
    position: relative;
    .contact-section-content {
      margin: 0 auto;
      max-width: 900px;
    }
    .button {
      width: 200px;
      margin: 80px auto;
      margin-top: 50px;
    }
  }

  .nav-link {
    width: 200px;
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    .main-section {
      padding-top: 0px;
      .button {
        margin-bottom: 95px;
      }
    }
  }

  @media (max-width: 768px) {
    h2 {
      padding: 0px 0px;
    }
    .main-section {
      .button {
        margin-bottom: 95px;
      }
    }
    .main-section-text {
      height: 235px;
    }
    section {
      margin: 75px auto;
    }
    .all-needs-section {
      margin-top: 0px;
      .flex {
        flex-direction: column;
      }
      .check-mark {
        margin: 40px auto;
      }
      .cell {
        width: 80%;
        margin: 15px auto;
      }
    }
    .developer-section {
      p {
        margin: 14px auto;
      }

      .button {
        display: block;
        margin: 60px auto;
        margin-top: 50px;
      }
    }

    .customer-case {
      p {
        margin: 14 auto;
      }
      h2 {
        padding-left: 30px;
      }

      .flex {
        flex-direction: column-reverse;
      }
      .cell {
        margin: 30px auto;
      }
      img {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .contact-section {
      padding-top: 75px;

      .button {
        min-width: 200px;
      }
      h2 {
        margin-left: 30px;
      }
      p {
        margin: 14px auto;
      }
    }
  }
  @media (max-width: 600px) {
    h2 {
      margin: 0px auto;
      margin-bottom: 30px;
    }
    .main-section-text {
      height: 270px;
    }
    .all-needs-section {
      margin-top: 0px;
      .cell {
        width: 90%;
      }
    }
    .customer-case {
      .cell {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 540px) {
  .e-sign-api-page {
    .main-section {
      padding-top: 0px;
    }
    .customer-case {
      strong {
        font-size: 1.3em;
      }
    }
  }
}

@media (max-width: 414px) {
  .e-sign-api-page {
    .main-section {
      margin-top: 0px;
      padding: 0px;
    }
    .main-section-text {
      height: 340px;
    }
    .all-needs-section {
      .cell {
        width: 95%;
      }
    }
    .customer-case {
      strong {
        font-size: 1.1em;
      }
    }
  }
}

@media (max-width: 375px) {
  .e-sign-api-page {
    .main-section-text {
      height: 330px;
    }
  }
}

@media (max-width: 320px) {
  .e-sign-api-page {
    .main-section-text {
      height: 370px;
    }
    .customer-case {
      strong {
        font-size: 1em;
      }
      .button {
        margin-left: auto;
        margin-right: auto;
      }
    }
    section {
      padding: 0px;
    }
    .all-needs-section {
      padding: 0px;
    }
    p:nth-child(5n) {
      width: auto;
      padding: 0px;
      margin-left: 30px;
      a {
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 280px) {
  .e-sign-api-page {
    .main-section {
      .button {
        margin-bottom: 80px;
      }
    }
    .main-section-text {
      height: 400px;
    }
  }
}
