.contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: unset;

  form {
    position: relative;
  }
  .button {
    position: absolute;
    right: 5px;
    top: calc(50% - (36px / 2));
  }

  label {
    display: block;
    margin-top: 4px;
    font-size: 11px;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="tel"] {
    text-align: left;
    font-size: 15px;
    background: $mid-grey;
    padding: 0.8em;
    border: none;
    border-radius: 3px;
    &::placeholder {
      color: #9b9b9b;
    }
  }
  .error-text {
    font-size: 11px;
    color: red;
  }
}
