.cases-card-section {
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  padding: 0;
  margin: 0 auto;

  a {
    text-decoration: none;
    font-weight: 800;
    font-size: 1.1em;
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
}
.cases-card-section-text {
  h2 {
    margin-top: 0px;
    text-align: center;
  }
}

.cases-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 2.5em;
  width: 100%;

  img {
    max-width: 90%;
    display: block;
    margin: 10px auto;
  }

  hr {
    border-bottom-color: $orange;
    width: 100%;
  }

  .case-card-wrapper {
    position: relative;
    transition: 300ms;
    max-width: 440px;
    height: 600px;
    margin: $spacing-unit * 2;
    width: calc(50% - 40px);
    background-color: white;
    padding: 30px;
    box-shadow: 0px 0px $spacing-unit #8891a0;

    .card-title {
      font-weight: 800;
      font-size: 1.2em;
      margin-left: 16px;
      margin-right: 16px;
    }

    .card-name {
      font-weight: 800;
      margin-bottom: 5px;
    }
    .card-job-title {
      margin-top: 0px;
    }
    .card-link {
      width: auto;
      position: absolute;
      bottom: 15px;
      left: 45px;
    }
  }

  .case-card-wrapper:hover {
    transform: scale(1.04);
    z-index: 1;
  }

  .case-card-upper-half {
    height: 300px;
    p {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
.case-card-lower-half {
  padding-bottom: 50px;
  p {
    max-width: 90%;
  }
  .card-link {
    margin: 1em auto;
  }
}

@media (max-width: 1024px) {
  .cases-card-section {
    width: 100%;
  }
  .cases-card-container {
    .case-card-wrapper {
      height: 610px;
      font-size: 0.8em;
      width: calc(50% - 40px);
      .case-card-upper-half {
        max-height: 270px;
      }
    }
  }
}

@media (max-width: 768px) {
  .cases-card-container {
    .case-card-wrapper {
      .card-title {
        font-size: 1em;
      }
    }
  }
}

@media (max-width: 680px) {
  .cases-card-container {
    .case-card-wrapper {
      height: unset;
      width: calc(100% - 10vw);
      .case-card-upper-half {
        height: 300px;
        height: auto;
        max-height: none;
      }
    }
  }
}

@media (max-width: 420px) {
  .cases-card-container {
    .case-card-wrapper {
      height: 650px;
    }
  }
}
@media (max-width: 360px) {
  .cases-card-container {
    .case-card-wrapper {
      height: 680px;
      padding: 15px 5px;
    }
  }
}
