.pink-background {
  background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
  background-size: 100% 595px;
}

@media (max-width: 1024px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 485px;
  }
}
@media (max-width: 768px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 465px;
  }
}
@media (max-width: 540px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 500px;
  }
}
@media (max-width: 414px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 540px;
  }
}

@media (max-width: 375px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 530px;
  }
}

@media (max-width: 360px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 530px;
  }
}

@media (max-width: 320px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 570px;
  }
}

@media (max-width: 280px) {
  .pink-background {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 590px;
  }
}

.bank-id-page {
  * {
    max-width: 100vw;
  }

  p {
    margin: 14px auto;
    padding: 0px 30px;
  }

  h1 {
    strong {
      font-weight: 600;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  .button {
    width: 200px;
    outline: none;
  }

  .flex {
    display: flex;
    justify-content: center;
  }

  .main-section {
    padding-bottom: 0px;
    h1 {
      margin-top: 0px;
    }
    button {
      display: block;
      width: 200px;
      margin: 0 auto;
      margin-bottom: 150px;
    }
    #dark-background-button {
      width: 150px;
      margin-top: 20px;
      margin-left: 5px;
      margin-bottom: 0px;
    }
    .demo-form .demo-form-left-side {
      p {
        padding: 0px;
      }
      h3 {
        margin-left: 0px;
      }
    }
  }

  .main-section-text {
    height: 252px;
  }

  .safety-and-security-section {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    h2 {
      padding: 0 30px;
    }
    .cell {
      margin: 15px;
      width: calc(50% - 30px);
    }
    #mac-book-bank-id {
      width: 100%;
    }
  }

  .popular-functions-section {
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;

    width: 100%;
    h2 {
      margin-top: 0px;
      text-align: center;
      margin-bottom: 2em;
    }
    p {
      margin: 14px auto;
      font-size: 14px;
    }
    strong {
      font-size: 16px;
    }
    .check-mark {
      color: white;
      background-color: #1414f0;
      text-align: center;
      border-radius: 50%;
      width: 40px;
      padding: 5px;
      margin: 0px auto;
      margin-left: 38%;
      margin-bottom: 40px;
    }
    .flex {
      flex-wrap: wrap;
    }
    .cell {
      margin: 15px 0;
      width: calc(50% - 5%);
    }
    a {
      display: block;
      width: 200px;
      margin: 0 auto;
    }
    .button {
      margin-top: 50px;
    }
  }

  .customer-case-section {
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 50px;
    max-width: 1200px;
    padding-top: 0px;

    p {
      max-width: 1200px;
    }
    .customer-case-name-title {
      p {
        margin: 5px 0px 5px 0px;
      }
      strong {
        font-size: 1em;
      }
    }
    .button {
      display: block;
      margin: 50px auto 0 auto;
      width: 200px;
    }
    h2 {
      padding: 0 30px;
      align-self: auto;
      margin-top: 0px;
    }
    img {
      display: block;
      width: 100%;
      max-width: 500px;
      margin: 30px auto;
      margin-top: 0px;
      margin-bottom: 50px;
    }
    strong {
      font-size: 1.5em;
    }
    p:nth-child(5n) {
      margin: 0px auto;
      margin-top: 50px;
    }
  }

  .contact-section {
    padding: 0px;
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 800px;
    max-width: none;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    width: 100%;
    position: relative;

    .contact-section-content {
      padding: 75px 30px;
      padding-top: 50px;

      padding-bottom: 0px;
      h2 {
        text-align: center;
      }
      .button {
        display: block;
        margin: 80px auto;
        margin-top: 50px;
      }
      margin: 0px auto;

      p {
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 1024px) {
  .bank-id-page {
    .main-section {
      padding-top: 0px;
      .button {
        margin-bottom: 95px;
      }
    }
  }
}
@media (max-width: 768px) {
  .bank-id-page {
    .main-section {
      margin-bottom: 0px;
      padding-bottom: 0px;
      .button {
        margin-bottom: 95px;
      }
    }
    .main-section-text {
      height: 235px;
    }
    .safety-and-security-section {
      padding-top: 0px;
      .flex {
        flex-direction: column;
      }

      .cell:nth-last-child(1) {
        margin-top: 30px;
      }

      .cell {
        width: 90%;
        margin: 0px auto;
        #mac-book-bank-id {
          display: block;
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    .popular-functions-section {
      .cell {
        margin: 0 auto;
      }
    }
    .contact-section {
      .contact-section-content {
        padding: 0 15px;
        padding-top: 50px;
      }
    }
  }
}
@media (max-width: 600px) {
  .bank-id-page {
    .main-section {
      padding: 0px;
      padding-top: 0px;
    }
    .main-section-text {
      height: 270px;
    }
    section {
      padding: 5px;
    }
    .flex {
      flex-direction: column;
    }
    .popular-functions-section {
      .check-mark {
        margin: 30px auto;
      }
      .cell {
        width: 100%;
      }
    }
    .customer-case-section {
      img {
        width: 70%;
      }
    }
    .contact-section {
      padding: 0px;
      .button {
        margin: 50px 0;
      }
    }
  }
}

@media (max-width: 420px) {
  .bank-id-page {
    .main-section {
      h1 {
        margin-top: 0px;
        margin-bottom: 15px;
      }
      .button {
        margin-top: 0px;
        margin-bottom: 100px;
      }
    }
    .main-section-text {
      height: 340px;
    }
    .customer-case-section {
      strong {
        font-size: 1.2em;
      }
      padding: 0px;
    }
    section {
      padding: 5px;
      margin: 75px auto;
    }
  }
}

@media (max-width: 375px) {
  .bank-id-page {
    .main-section-text {
      height: 330px;
    }
  }
}

@media (max-width: 320px) {
  .bank-id-page {
    .main-section-text {
      height: 370px;
    }
  }
}

@media (max-width: 280px) {
  .bank-id-page {
    .main-section {
      .button {
        margin-bottom: 80px;
      }
    }
    .main-section-text {
      height: 400px;
    }
  }
}
