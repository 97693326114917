.questions-page {
  padding-top: 80px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;

  .breadcrumb {
    color: #4a4a4a;
  }

  .back-to-service-arrow {
    text-decoration: none;
    margin-right: 5px;
    color: #4a4a4a;
  }

  .api-button-wrapper {
    .api-button {
      display: block;
      width: 200px;
      font-family: "acumin-pro";
      font-style: normal;
      font-weight: 600 !important;
      margin-top: 0;
      margin-bottom: 10px;
      background-color: #2e333d;
      color: white;
      padding: 6px 12px;
      font-size: 0.92em;
      border: none !important;
      border-radius: 15px;
      text-decoration: none;
      &:hover {
        transform: scale(1.04);
        opacity: 1;
      }
    }
  }

  .related-question-list {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2em;
    list-style: disc;
    font-size: 16px;
    color: black;
    li {
      margin-top: 5px;
    }
  }
}
