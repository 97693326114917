.cookie-consent-component {
  .consent-button {
    background: #1414f0 !important;
    color: white !important;
  }

  .CookieConsent {
    align-items: stretch !important;
    padding: 0 calc(15vw);
    @media (max-width: 600px) {
      padding: 0 calc(5vw);
    }
    @media (max-width: 350px) {
      padding: 0;
    }
    > div {
      margin: 15px 0;
      font-size: 14px;
      &:last-child {
        display: flex;
        align-items: center;
        margin-left: auto;
      }
      button {
        min-height: 35px;
      }
    }
    background: rgba(53, 53, 53, 0.6) !important;
    button {
      padding: 0.4em 0.5em !important;
      border-radius: 20px !important;
      width: 150px;
    }

    #rcc-decline-button {
      color: black !important;
      background-color: #eaeaea !important;
    }
  }

  @media (max-width: 360px) {
    .CookieConsent {
      button {
        width: 100px;
      }
    }
  }
}

.open-cookie-consent {
  border: none;

  .fake-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .open-cookie-consent {
    .fake-link {
      bottom: 0px;
    }
  }
}
