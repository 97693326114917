th,
td {
  vertical-align: top;
}

.table-functions {
  border-collapse: collapse;

  tbody tr:nth-child(odd) {
    background: #eaeaea;
  }

  th {
    color: #fa5a21;
    font-size: 19px;
    font-weight: normal;

    &.rotate {
      position: relative;
      text-align: left;
      height: 100px;

      div {
        border: 1px solid #ff7d00;
        border-top-width: 0;
        border-radius: 0 0 7px 7px;
        padding: 15px 0;
        height: 100px;
        line-height: 49px;
        position: absolute;
        top: 0;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        margin-left: -1px;
      }
    }
  }

  td {
    font-size: 16px;

    p {
      position: relative;
      margin: 0;
    }
  }

  tbody {
    td {
      padding: 8px 10px;
    }

    td:nth-child(1) {
      padding-top: 5px;
      color: #1414f0;
      font-size: 19px;
    }
  }
}
