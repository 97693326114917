.icon {
  position: relative;
  padding-left: 15px;

  &-plus {
    &:before {
      content: "+";
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
    }

    &__blue:before {
      background: #174886;
    }
  }

  &-check {
    padding-left: 1em;

    &:before {
      content: "";
      position: absolute;
      left: 3px;
      top: 0.45em;
      display: inline-block;
      width: 0.25em;
      height: 0.5em;
      border: solid $text-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &__blue:before {
      border-color: #174886;
    }
  }

  &-white:before {
    color: white;
  }
}
