.integrityPolicyPage {
  margin: 0 auto;

  h1,
  h2 {
    max-width: 840px;
    align-self: start;
  }

  .table-section-with-h2 {
    h2 {
      margin-top: 0px;
      font-size: 1.3em;
    }
  }

  .text-section {
    margin-top: 3em;
  }

  h1 {
    align-self: center;
  }

  h3 {
    max-width: 840px;
  }

  p {
    margin-left: 0px;
  }

  .main-section {
    margin-top: 0;
    padding-top: 80px;
  }

  .last-section {
    padding-bottom: 5rem;
  }

  .container p {
    margin: 1em;
  }

  section {
    max-width: 840px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
  }

  padding: 1em;

  ul {
    padding-left: 1.1em;
  }

  li {
    list-style-type: disc;
    font-size: 16px;
    margin: 1em auto;
    max-width: 840px;
  }

  .table {
    margin: 0 auto;
    display: block;
  }

  .flex {
    display: flex;
    margin-top: -1px;
    margin-left: 0px;
  }

  .cell {
    outline: 1px solid;
    margin-top: 2px;
    margin-left: 1px;
    padding-left: 0.5em;
    width: 100%;
  }

  .pink-cell {
    background-color: #f5eeef;
    text-align: center;
  }

  .grey-cell {
    background-color: #cacaca;
  }

  .summary-section {
    width: calc(840px - 2em);
    border: solid black 1px;
  }

  .personal-data-anchor {
    display: block;
    position: relative;
    top: -400px;
    visibility: hidden;
  }

  .anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }

  @media (max-width: 1024px) {
    .main-section {
      padding-top: 0px;
    }
  }

  @media (max-width: 767px) {
    .table-wrapper {
      overflow-x: auto;
    }

    section {
      padding: 0;
    }

    .flex {
      min-width: calc(768px - 30px);
    }

    .cell {
      outline: 1px solid;
      margin-top: 2px;
      margin-left: 1px;
      padding-left: 0.5em;
      width: calc(100% - 2px);
    }

    .pink-cell {
      text-align: left;
    }

    .multi-row-cell {
      width: calc(33.3% - 1px);
    }
  }

  @media (max-width: 414px) {
    .personal-data-anchor {
      top: -460px;
    }
  }

  @media (max-width: 320px) {
    .personal-data-anchor {
      top: -520px;
    }
  }

  @media (max-width: 280px) {
    .personal-data-anchor {
      top: -630px;
    }
  }
}
