.prices-bankid-page {

  h3 {
    height: 50px;
  }

  .cell {
    width: 29%;
    max-width: 360px;
    @media (max-width: 768px) {
      width: unset;
      display: block;
    }
  }

  .button {
    width: 175px;
  }
}
