// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.container {
  .home-page {
    p {
      width: 90%;
    }
  }
  p {
    max-width: 840px;
    font-size: 16px;
    margin: 1em auto;
  }
}

.orange {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 80%;
}

section {
  h2 {
    margin-top: 35px;
    font-weight: 300;
  }
}

.mainHeader {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 0px;
  padding-top: 0px;

  h1 {
    font-weight: 900;
    font-size: 3em;
    margin: 2vw auto;
    span {
      font-size: 0.5em;
      font-weight: 300;
    }
  }
  .button {
    margin-bottom: 0px;
    margin-top: 4vw;
    font-size: 1em;
    padding-left: 30px;
    padding-right: 30px;
  }
  .main-header-p {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .container {
    .home-page {
      .mainHeader {
        h1 {
          font-size: 1.6em;
        }
      }
    }
  }
  h2 {
    width: 90%;
  }
  .orange {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}
