.partner-slider-wrapper {
  max-width: 840px;
  margin: 0px auto;
  margin-top: 100px;

  .hr-top {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .hr-bottom {
    margin: 0 auto;
    margin-top: 5px;
  }

  .partner-slider {
    height: 100px;
    max-width: 80%;
    margin: 0 auto;
    img {
      height: 60px;
      width: auto;
      margin: 0 20px;
    }
  }
}

@media (max-width: 900px) {
  .partner-slider-wrapper {
    max-width: 700px;
  }
}
@media (max-width: 768px) {
  .partner-slider-wrapper {
    max-width: 600px;
  }
}

@media (max-width: 650px) {
  .partner-slider-wrapper {
    max-width: 450px;
    .partner-slider {
      max-width: 65%;
      img {
        height: 45px;
      }
    }
  }
}

@media (max-width: 550px) {
  .partner-slider-wrapper {
    .partner-slider {
      img {
        height: 30px;
      }
    }
  }
}

@media (max-width: 500px) {
  .partner-slider-wrapper {
    max-width: 320px;
    .partner-slider {
      height: 70px;
      img {
        height: 30px;
      }
    }
  }
}
@media (max-width: 400px) {
  .partner-slider-wrapper {
    max-width: 250px;
    .partner-slider {
      height: 60px;
      img {
        height: 20px;
      }
    }
  }
}

@media (max-width: 300px) {
  .partner-slider-wrapper {
    max-width: 230px;
    .partner-slider {
      height: 60px;
      img {
        height: 20px;
      }
    }
  }
}
