.contact-us-page {
  max-width: 1366px;
  margin: 0 auto;

  .contact-info {
    margin-top: 3em;
    font-weight: 800;
  }

  h1 {
    margin-bottom: 1em;
    margin-top: 0px;
  }
  .orange {
    width: 100%;
    margin: 0px;
    margin-bottom: 2em;
  }
  .contact-form-left-side {
    width: calc(50% - 100px);
    margin-bottom: 0px;
  }
  .contact-form-right-side {
    width: calc(50% + 100px);
    input,
    textarea {
      margin: 5px;
      border: solid 2px #1414f0;
      border-image-slice: 1;
      border-radius: 20px;
      background: white;
      outline: none;
      padding: 12px;
    }

    textarea {
      height: 7em;
    }

    label {
      margin-left: 22px;
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
  }
  form {
    border-radius: 10px;
    label {
      font-size: 1em;
      color: black;
    }
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 1150px;
    padding: 50px;
  }

  .cell {
    margin: 3em;
  }

  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }

  .button {
    display: block;
    position: static;
    width: 200px;
    margin-top: 2em;
  }

  @media (max-width: 1024px) {
    .contact-form-left-side {
      width: 40%;
    }
    .contact-form-right-side {
      width: 80%;
    }
  }

  @media (max-width: 768px) {
    .flex {
      flex-direction: column;
    }
    .contact-form-left-side {
      width: calc(100% - 6em);
      margin-bottom: 0px;
    }
    .contact-form-right-side {
      width: calc(100% - 6em);
    }
  }

  @media (max-width: 540px) {
    .contact-form-right-side {
      margin: 2em auto;
      width: calc(100% - 2em);
    }
  }
  @media (max-width: 414px) {
    .button {
      margin-left: auto;
      margin-right: auto;
    }

    form {
      padding: 25px;
    }
  }
  @media (max-width: 360px) {
    .contact-form-left-side {
      width: calc(100% - 4em);
      margin: 2em;
    }
  }
  @media (max-width: 320px) {
    .contact-form-left-side {
      width: calc(100% - 2em);
      margin: 1em;
    }
    .contact-form-right-side {
      width: calc(100% - 1em);
    }
  }
  @media (max-width: 280px) {
    .contact-form-right-side {
      width: calc(100%);
      margin-bottom: 0px;
    }
  }
}
