.legal-and-technical-page {
  section {
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: 2rem;
  }

  h3 {
    margin-top: 0px;
  }

  a {
    max-width: 840px;
    width: 100%;
    margin: 1em auto;
  }

  span {
    color: #1414f0;
    cursor: pointer;
  }

  .arrow-span {
    max-width: 840px;
    color: #1414f0;
    margin: 1em auto;
    width: 100%;
  }

  .index {
    background-color: white;
    max-width: 640px;
    margin: 4em auto;
    margin-bottom: 0px;
    border-radius: 5px;
    box-shadow: 2px 3px 21px -2px rgba(0, 0, 0, 0.05);
    padding: 2em;
    width: 95%;

    span:hover {
      text-decoration: underline;
    }
  }

  ul {
    max-width: 840px;
    width: 100%;
    list-style-type: disc;
    margin: 0.5em auto;
    padding-left: 2em;
    font-size: 16px;
    li {
      line-height: 1.7em;
    }
  }

  @media (max-width: 414px) {
    .index {
      margin: 2em auto;
      padding: 1em;
    }
  }
}
