// -----------------------------------------------------------------------------
// This file contains styles that are specific to the index page.
// -----------------------------------------------------------------------------

.page-index {
  background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
  background-size: 100% 1150px;
  @include for-phone-only {
    background-size: 100% 800px;
  }

  .button {
    outline: none;
  }

  .start-04 {
    .customer-info {
      display: flex;
      flex-direction: column;

      #moneyGo-logo {
        width: 50%;
        display: block;
        float: right;
      }

      p {
        text-align: right;
      }
    }

    img {
      margin: 0 auto;
      width: 100px;
      height: auto;
    }

    .customer-quote {
      font-size: 40px;
      line-height: 51px;
      font-weight: 800;
    }

    .customer {
      font-size: 16px;
    }
  }

  .customer-info-img-wrapper {
    display: flex;
    flex-direction: row;

    .customer-info {
      display: flex;
      justify-content: flex-end;
      padding: 5px;
      width: calc(50% - 5px);
      border-right: 2px solid #c6c6c6;
      padding: 10px;
      padding-right: 30px;

      .name-company {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      strong {
        padding: 5px;
        text-align: right;
        font-size: 16px;
        font-weight: 700;
        color: black;
      }

      p {
        font-size: 16px;
        padding: 5px;
        margin-bottom: 28px;
        margin-top: 0px;
      }
    }

    .customer {
      margin-top: 0px;
      padding: 10px;
      width: calc(50% - 5px);
    }

    #lower-customer {
      text-align: justify;
    }
  }

  // Temporary styles for hiding unused containers
  .start-07,
  .start-07 + hr,
  .start-08,
  .start-08 + hr,
  .start-11,
  .start-11 + hr {
    display: none !important;
  }
}

section {
  display: flex;
  flex-direction: column;
  padding: 5rem 5% 6rem;

  h1 {
    text-align: center;
    color: #20292b;

    strong {
      line-height: 1em;
      font-weight: 800;
    }
  }

  h2 {
    margin-top: 0;
    align-self: center;
    @include for-phone-only {
      line-height: 1.2em;
      margin-bottom: 0.5em;
    }
  }

  .teasers {
    display: flex;
    justify-content: space-between;

    .teaser {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 3px;

      img {
        width: 100%;
        height: auto;
      }

      p {
        font-size: 16px;
      }
    }

    .col-3 {
      .checklist {
        min-width: 75%;
        max-width: 230px;
        align-self: center;
      }

      .button {
        @include for-phone-only {
          padding-left: 5px;
          padding-right: 5px;
          width: 100%;
          width: 200px;
        }
      }
    }

    .col-4 {
      img {
        max-width: 300px;
        margin: 0 auto;
      }

      .content {
        margin-bottom: 2em;
      }

      .button {
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        margin-top: auto;

        & + p {
          margin: 1em auto;
        }

        @include for-phone-only {
          width: 200px;
        }
      }
    }

    @include for-phone-only {
      display: block;

      .col-3,
      .col-4 {
        width: unset;
        margin-bottom: 2rem;
      }
    }
  }

  &.start-animation {
    margin: 0 auto;
    align-items: center;
    position: relative;
    max-width: 90vw;

    .slick-slider-container {
      width: 100%;
      max-width: 455px;
      margin-bottom: 2em;
    }

    .slick {
      &-list {
        margin: 0 4%;
      }

      &-arrow {
        height: 70px;
        width: 70px;

        @media (max-width: 600px) {
          height: 28px;
          width: 28px;
        }
      }

      &-prev {
        left: -10%;
        @include for-phone-only {
          left: -2%;
        }

        &::before {
          height: 2em;
          width: 2em;
          @include for-phone-only {
            height: 1em;
            width: 1em;
          }
        }
      }

      &-next {
        right: -10%;
        @include for-phone-only {
          right: -2%;
        }

        &::before {
          height: 2em;
          width: 2em;
          @include for-phone-only {
            height: 1em;
            width: 1em;
            right: -2%;
          }
        }
      }

      &-dots {
        bottom: 0;
      }
    }

    .phone {
      position: relative;
      max-width: 455px;
      margin-bottom: 3rem;
      padding: 0 15px;

      .screens {
        width: 100%;
        height: auto;

        .screen {
          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }

    .teasers {
      position: absolute;
      width: 100%;
      top: 50px;
      flex-direction: column;

      .teaser {
        margin: 0px;
        position: absolute;
        line-height: 1.2em;
        background: white;
        padding: 35px 40px;
        box-shadow: 6px 6px 53px rgba(74, 74, 74, 0.1);
        border: 1px solid #dbdbdb;
        border-radius: 5px;

        h3 {
          font-size: 28px;
          margin: 0;
          color: $orange;
        }

        &-01-01 {
          text-align: center;
          width: 280px;
          right: 120%;
          top: 200px;

          p {
            font-size: 12px;
          }
        }
        @at-root {
          html:lang(en) {
            .teaser-01-01,
            .teaser-02-02 {
              display: none;
            }
          }
          html:lang(nb), html:lang(no) {
            .teaser-01-01 {
              display: none;
            }
          }
        }

        &-01-02,
        &-02-02 {
          width: 400px;
          right: 0;
          left: 95%;
          top: 600px;

          a {
            display: block;
            background-color: #1414f0;
            width: 60%;
            margin: 0 auto;
            margin-top: 15px;
            color: white;
            border-radius: 3px;
            min-width: 190px;
            text-decoration: none;
            border-radius: 25px;
            padding: 15px 30px;
            font-size: 18px;
          }

          @media (max-width: 420px) {
            a {
              width: 175px;
              min-width: auto;
            }
          }

          @media (max-width: 320px) {
            a {
              padding: 15px;
              width: 145px;
            }
          }

          p {
            font-size: 16px;
          }
        }

        &-01-01 {
          a {
            min-width: 180px;
            display: block;
            background-color: #1414f0;
            width: 50%;
            margin: 0 auto;
            margin-top: 15px;
            color: white;
            padding: 5px;
            border-radius: 3px;
            text-decoration: none;
            border-radius: 25px;
            padding: 15px 30px;
            font-size: 18px;
          }

          @media (max-width: 420px) {
            a {
              width: 175px;
              min-width: auto;
            }
          }

          @media (max-width: 320px) {
            a {
              padding: 15px;
              width: 145px;
            }
          }
        }

        &-02-03 {
          a {
            min-width: 180px;
            display: block;
            background-color: #1414f0;
            width: 60%;
            margin: 0 auto;
            margin-top: 15px;
            color: white;
            padding: 5px;
            border-radius: 3px;
            text-decoration: none;
          }
        }

        &-02-03 {
          width: 326px;
          left: -65%;
          top: 100px;

          p {
            font-size: 16px;
          }
        }

        @include for-phone-only {
          &-01-01 {
            text-align: center;
            width: 280px;
            left: 0;
            top: 400px;

            p {
              font-size: 12px;
            }
          }

          &-01-02,
          &-02-02 {
            width: 280px;
            left: 0;
            top: 500px;

            p {
              font-size: 16px;
            }
          }

          &-02-03 {
            width: 280px;
            left: 0;
            top: 600px;
          }
        }
      }

      @media (max-width: 1400px) {
        .teaser {
          &-01-01 {
            right: 100%;
          }

          &-01-02 {
            left: 85%;
          }

          &-02-03 {
            left: -55%;
          }

          &-02-02 {
            left: 87%;
          }
        }
      }

      @media (max-width: 1215px) {
        .teaser {
          &-01-01 {
            right: 85%;
          }

          &-01-02 {
            left: 66%;
          }

          &-02-03 {
            left: -43%;
          }

          &-02-02 {
            left: 66%;
          }
        }
      }

      @media (max-width: 1024px) {
        .teaser {
          &-01-01 {
            right: 75%;
          }

          &-01-02 {
            left: 50%;
          }

          &-02-03 {
            left: -25%;
          }

          &-02-02 {
            left: 50%;
          }
        }
      }

      @media (max-width: 850px) {
        .teaser {
          &-01-01 {
            right: 65%;
          }

          &-01-02 {
            left: 50%;
          }

          &-02-03 {
            left: -15%;
          }
        }
      }

      @media (max-width: 768px) {
        .teaser {
          &-01-01 {
            right: 0px;
          }

          &-01-02 {
            left: 0px;
          }

          &-02-03 {
            left: 0px;
          }

          &-02-02 {
            left: 0px;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        position: relative;
        top: 2rem;

        .teaser {
          position: relative;
          top: unset;
          width: 100%;
          margin-bottom: 1em;
        }
      }
    }

    .content {
      max-width: 790px;
      margin: 0 1rem;

      h2 {
        text-align: center;
      }

      p {
        font-size: 16px;
        line-height: 1.5em;
      }
    }
  }

  .fadeOut {
    @media only screen and (max-width: 768px) {
      overflow: hidden;
      height: 0;
    }
  }

  &.start-02 {
    .teaser {
      background: #f2f2f2;
      padding: 40px 30px 20px;

      h3 {
        font-size: 19px;
        font-weight: bold;
        margin: 0;
        color: $text-color;
      }

      .price {
        margin-top: 1rem;
        color: $brand-color;
        font-size: 43px;
        line-height: 1em;
        font-weight: bold;
      }

      ul {
        text-align: left;
        margin: 10px 0 20px;
      }

      li {
        margin-bottom: 8px;
        font-size: 20px;
      }

      .more-info {
        margin-top: 20px;
        color: $text-color;
        font-size: 12px;
      }

      .button {
        width: 100%;
        max-width: 220px;
      }

      p a {
        color: inherit;

        &:hover {
          color: $brand-color;
        }
      }

      @include for-tablet-portrait-up {
        @media only screen and (max-width: 768px) {
          padding: 40px 2% 20px;

          .price {
            font-size: 27px;
          }

          ul li {
            font-size: 0.8em;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }

  &.start-03 {
    h3 {
      color: black;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 0.5em;
    }

    p {
      margin: 0 0 0.25em;
      a {
        color: inherit;

        &:hover {
          color: $brand-color;
        }
      }
    }

    .button {
      margin-top: 2em;
      width: 100%;
      max-width: 220px;
    }
  }

  &.start-04 {
    margin: 0 auto;
    max-width: 830px;
    span {
      text-align: center;
      font-size: 40px;
      line-height: 51px;
      font-weight: 800;
    }

    h2 {
      text-align: center;

      strong {
        padding-top: 0.5em;
        font-size: 75px;
        line-height: 1em;
        font-weight: 800;
        color: $text-color;

        @include for-phone-only {
          font-size: inherit;
        }
      }
    }
  }

  &.start-05 {
    .client-logos {
      margin: 0 auto;
      width: 100%;
      max-width: 716px;

      div img {
        height: 50px;
        width: auto;
        margin: 0 20px;
      }

      .slick-prev {
        left: -7%;
      }

      .slick-next {
        right: -7%;
      }

      @include for-phone-only {
        width: 80%;

        div img {
          height: 30px;
          margin: 0 10px;
        }

        .slick-prev {
          left: -13%;
        }

        .slick-next {
          right: -13%;
        }
      }
    }
  }

  &.start-06 {
    margin: 0 auto;
    text-align: center;
    max-width: 840px;

    img {
      margin-bottom: 1.5rem;
    }

    p {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

    button {
      margin-top: 2rem;
      width: 200px;
    }
  }

  &.start-07 {
    display: none; // Hiding test agreements in front end

    font-size: 15px;

    .link-lists {
      margin: 1rem 0 3rem;
      @include for-phone-only {
        display: block;

        .link-list {
          width: 100%;
        }
      }
    }

    .terms {
      font-style: italic;
      color: #9b9b9b;
    }

    .contactform .button {
      min-width: 150px;
      @include for-phone-only {
        min-width: 100px;
      }
    }
  }

  &.start-08,
  &.start-11,
  &.start-12 {
    flex-direction: row;

    & > div {
      width: 46%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .image {
      padding-top: 3rem;
      text-align: right;
    }

    .text {
      vertical-align: middle;
      padding: 2em 0 0 7%;

      p,
      ol,
      ul {
        font-size: 16px;
        line-height: 22px;
      }

      ul {
        list-style-type: disc;
        margin-left: 1.35em;
      }

      > .button {
        margin-top: 2rem;
        min-width: 180px;
      }
    }

    @include for-phone-only {
      flex-direction: column;

      & > div,
      .text {
        width: unset;
        padding: unset;

        > .button {
          display: block;
          margin: 2rem auto 0;
        }
      }
    }
  }

  &.start-09 {
    margin: 0 auto;
    text-align: center;
    max-width: 830px;

    .customer-quote {
      font-size: 40px;
      line-height: 51px;
      font-weight: 800;

      @include for-phone-only {
        font-size: 30px;
        line-height: 1.3em;
      }
    }

    .customer {
      font-size: 16px;
      line-height: 21px;
      margin-top: 1rem;

      strong {
        color: black;
        font-size: 16px;
        line-height: 37px;
      }
    }

    img {
      margin: 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  &.start-10 {
    margin: 0 auto;
    max-width: 830px;
    align-items: center;

    h2 {
      text-align: center;
    }

    img {
      object-fit: none;
    }
  }

  &.start-12 {
    flex-wrap: wrap;

    .text {
      width: 50%;
    }

    .image {
      width: 50%;
      padding-left: 3rem;
      text-align: center;
    }

    @include for-phone-only {
      display: block;

      .text,
      .image {
        width: 100%;
      }

      .image {
        padding: unset;
        text-align: center;
      }
    }

    .contactform {
      margin-top: 3rem;

      @include for-phone-only {
        padding-bottom: 2rem;
      }
    }
  }

  .teaser .button {
    width: 72%;
  }

  .col-3 .button {
    min-width: 90px;
  }

  .case-study {
    margin: 2rem auto 0;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    line-height: 2em;

    .client {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      flex: 2 0;
      padding-right: 30px;
      //border-right: 2px solid #c6c6c6;

      img {
        height: 50px;
        width: 50px;
        border-radius: 100%;
        object-fit: cover;
        margin-left: 10px;
      }

      #moneyGo-client-img {
        border-radius: 0;
        display: block;
        height: 79px;
        width: auto;
        margin: 0 auto;
        margin-top: 0px;
        margin-bottom: 31px;
      }

      strong {
        font-size: 16px;
        line-height: 1em;
        color: black;
      }
    }

    .case-study-description {
      flex: 2 0;
      padding-left: 25px;

      p {
        margin: 0;
      }
    }

    @include for-phone-only {
      display: block;

      .client {
        border-right: unset;
        margin-bottom: 1rem;
        text-align: unset;
        padding: unset;
        justify-content: initial;
      }

      .case-study-description {
        padding: unset;
      }
    }
  }

  .link-lists {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-self: center;
    font-size: 16px;
    line-height: 17px;

    @include for-phone-only {
      width: 80%;
    }
  }

  .link-list {
    flex: 1 0 auto;
    width: 33%;

    &:not(:last-child) {
      margin-right: 1.25rem;
    }

    a {
      display: block;
      padding: 1em 1.5em 1em 0;
      border-bottom: 1px solid $brand-color;
      color: $text-color;

      &:hover {
        color: $brand-color;
        text-decoration: none;
      }
    }
  }

  .contactform form,
  .contactform form input[type="text"],
  input[type="email"] {
    width: 100%;
    max-width: 520px;
  }

  @include for-phone-only {
    // padding: 3rem 0 5rem;
  }
}

.start-12 {
  main {
    hr {
      width: 90%;
      margin-left: 20px;
    }

    max-width: 800px;
    margin: 0 auto;
  }

  .partners-logos {
    margin: 0 auto;
    width: 100%;
    max-width: 716px;
    margin: 20px;

    div img {
      height: 50px;
      width: auto;
      margin: 0 20px;
    }

    .slick-prev {
      left: -7%;
    }

    .slick-next {
      right: -7%;
    }

    @include for-phone-only {
      width: 80%;

      div img {
        height: 30px;
        margin: 0 10px;
      }

      .slick-prev {
        left: -13%;
      }

      .slick-next {
        right: -13%;
      }
    }
  }
}
