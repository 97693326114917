.closeOverlay {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 31px;
  height: 31px;
  opacity: 0.3;
  background-size: cover;
}
.closeOverlay:hover {
  opacity: 1;
  cursor: pointer;
}
.closeOverlay:before,
.closeOverlay:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.closeOverlay:before {
  transform: rotate(45deg);
}
.closeOverlay:after {
  transform: rotate(-45deg);
}
