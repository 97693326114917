.nav-main {
  .header-ul {
    .service-dropdown-ul {
      padding: 0.8em;
      background: white;
      width: 250px;
      display: none;
      flex-direction: column;
      position: absolute;
      top: 71px;
      border-radius: 0px 0px 10px 10px;
      box-shadow: 5px 5px 5px #acacac;
      margin-left: 10.4em;
      .dropdown-li {
        padding: 0.5em;
        width: 100%;
        height: 43px;
      }
      // media query is a Safari hack
      @media not all and (min-resolution: 0.001dpcm) {
        margin-left: 75px;
      }
    }
    #service-link {
      // What is this for? ATM it's a bug. 
      // padding-bottom: 0.6em;
    }
    #service-link:hover {
      border-bottom: solid $brand-color;
    }
    #service-link-li:hover {
      background-color: white;
    }

    @media (hover: none), (max-width: 1024px) {
      .dropdown-li {
        height: auto !important;
      }
      .active a {
        border-bottom: solid $brand-color;
      }
      li {
        a:active {
          border: none;
        }
      }
      #service-link:hover {
        border-bottom: none;
      }
      #service-link-li:hover {
        background-color: transparent;
      }
      .service-dropdown-ul {
        width: 35em;
        display: flex;
        position: relative;
        left: -100px;
        margin-left: 0px;
        top: 40px;
        box-shadow: none;
        background: transparent;
        margin-bottom: 30px;

        .dropdown-li {
          border: none;
        }
      }
    }
  }
}

.nav-main .header-ul {
  @media (hover: none), (max-width: 1024px) {
    #service-link {
      display: block;
    }
    #service-link-li {
      display: flex;
      flex-direction: column;

      #service-link {
        width: 120px;
        background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
        background-size: 100% 1150px;
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .service-dropdown-ul {
        width: 285px;
        font-size: 0.8em;
//        margin-left: 15px;
        border-radius: 10px;
        border-top-left-radius: 0px;
        background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
        background-size: 100% 1150px;
        position: static;
        margin-bottom: 0px;
        padding: 0px;
        a {
          padding-left: 0px;
          margin-left: 20px;
        }
      }
    }
    #main-logo-li {
      margin-right: 0;
    }
    .invisible-li {
      width: 0px;
    }
    padding: 0 10px;
    font-size: 0.88em;
  }
}

@media (hover: none), (max-width: 1024px) {
  @media (max-width: 1024px) {
    .nav-main .header-ul {
      #service-link-li {
        #service-link {
          width: 85px;
        }
        .service-dropdown-ul {
          width: 225px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .nav-main .header-ul {
      #service-link-li {
        #service-link {
          width: 100px;
        }
        .service-dropdown-ul {
          width: 225px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .nav-main .header-ul {
      #service-link-li {
        #service-link {
          width: 85px;
        }
        .service-dropdown-ul {
          width: 225px;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .nav-main .header-ul {
      #service-link-li {
        #service-link {
          width: 70px;
        }
        .service-dropdown-ul {
          width: 200px;
        }
      }
    }
  }
}
