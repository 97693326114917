// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: #4a4a4a;
  font-size: 12px;
  line-height: 18px;
  padding: 2rem 1rem;
  gap: 1rem;

  a,
  a:hover,
  a:active {
    color: white;
  }

  .footer-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sub-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  .contact-button-footer {
    outline: none;
    display: block;
    background-color: transparent;
    color: white;
    border: none;
    padding-left: 0;
    &:hover {
      text-decoration: underline;
    }
  }

  .footer-column {
    width: 225px;
    padding: 0 1.3rem;
    border-left: 1px solid white;
  }

  .footer-column:last-child {
    border-right: 1px solid white;
  }

  .language {
    text-align: right;
    border-left: unset;
    display: none;

    form {
      display: none; // hide
    }
  }

  .language-switch-wrapper {
    position: relative;

    .language-switch {
      background-color: #1414f0;
      color: white;
      border: none;
      position: absolute;
      bottom: 0rem;
      left: 1.3rem;
      padding: 3px 5px;
      border-radius: 7px;
      outline: none;
      option {
        background-color: #1414f0;
        color: white;
      }
    }

    @media (max-width: 600px) {
      height: 4rem;
      .language-switch {
        bottom: 1.2rem;
      }
    }
  }

  @include for-phone-only {
    .footer-content {
      flex-direction: column;
    }

    .footer-column {
      width: 100%;
      padding: 0 1.3rem 1.3rem;
      border-bottom: 1px solid white;
      border-left: none;
    }

    .footer-column:last-child {
      border-right: none;
    }

    .address {
      width: 100%;
      padding: 1.3rem;
      border-left: unset;
      border-bottom: 1px solid white;
    }

    .language {
      text-align: unset;
      border-bottom: unset;
    }
  }
}
