.visma-footer-logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: auto;

  @include for-phone-only {
    max-width: 120px;
  }
}
