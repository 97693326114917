// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  min-height: 450px;
  margin-top: 7vw;
}

.standard-main {
  padding-top: 3vw;
  padding-bottom: 6rem;
  h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 840px;
  }

  h3 {
    max-width: 840px;
    width: 100%;
    margin: 1em auto;
    // margin-left: auto;
  }

  p {
    max-width: 840px;
    width: 100%;
    font-size: 16px;
  }
}

.anchor-standard {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
  height: 0;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.nowrap {
  white-space: nowrap;
}

.display-none {
  display: none;
}

@media (hover: none), (max-width: 1024px) {
  .container {
    margin-top: 15vh;
  }
  
  .no-scroll{
    overflow: hidden;
  }
}
