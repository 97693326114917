.support-main-section {
  max-width: 855px;
  margin: 0 auto;
  padding: 80px 30px 100px 30px;

  h1 {
    text-align: left;
    margin-top: 0px;
  }

  p {
    margin: 14px 0px;
  }

  .content {
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    padding-top: 0px;
  }
}
