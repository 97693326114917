#digital-signing {
  h2 {
    margin-top: 60px;
  }

  img {
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
