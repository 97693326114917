.developer-section {
  margin-top: 150px;
  padding-top: 0px;
  padding-bottom: 0px;
  h2 {
    margin-top: 0px;
    text-align: center;
  }
  img {
    object-fit: none;
  }

  a {
    text-decoration: none;
  }

  .button {
    display: block;
    margin: 60px auto;
    width: 200px;
    outline: none;
  }

  .code-example {
    display: flex;
    padding: 1.5rem 0;
    background: #f4f4f4;
    width: 100%;
    max-width: 800px;
    align-items: center;
    margin: 0 auto;
    margin-top: 50px;
    .col-left {
      font-size: 12px;
      background: white;
      flex: 1 0 auto;
      counter-reset: line;
      overflow-x: hidden;
      overflow-y: scroll; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
      height: 305px;
      max-width: calc(100% - 210px);

      span {
        display: block;
        color: #bdab49;
        font-family: "Lucida Console", Monaco, monospace;
        white-space: pre;

        &::before {
          counter-increment: line;
          content: counter(line);
          display: inline-block;
          text-align: right;
          padding: 0 0.7em;
          margin-right: 0.5em;
          width: 2.5em;
          background: #f2feef;
          color: #67c9a3;
          font-family: "Courier New", Courier, monospace;
          line-height: 1.6em;
          font-weight: 700;
        }
      }
    }
    nav {
      display: flex;
      flex: 0 1 auto;
      padding-left: 1em;

      ul {
        flex: 1;
        display: unset;
        font-size: 16px;
        line-height: 1em;
        position: relative;
        li {
          display: inline-flex;
          position: relative;
          margin-bottom: 12px;
          a {
            padding-left: 1.5em;
            color: #1414f0;
          }
          &:before {
            position: absolute;
            left: 0;
            margin-top: 1px;
            content: "";
            width: 1em;
            height: 1em;
            background: #979797;
            border-radius: 5px;
          }
          &:last-child {
            position: relative;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 890px) {
  .developer-section {
    .code-example {
      max-width: 90vw;
    }
  }
}

@media (max-width: 768px) {
  .developer-section {
    margin-top: 100px;
  }
}

.developer-section {
  @media (max-width: 600px) {
    .code-example {
      flex-direction: column;
      align-items: stretch;
      .col-left {
        max-width: 100%;
        border-right: 20px solid #f4f4f4;
      }
      .nav {
        width: unset;
        margin-top: 20px;
        padding-left: 5vw;
        li {
          min-width: 49%;
        }
      }
    }
  }
  @media (max-width: 425px) {
    .code-example .nav {
      li {
        width: 100%;
      }

    }
  }
}
