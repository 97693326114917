.featured-video-section {
    padding: 0px;
    video {
        margin: 2rem 0;
        width: 100%;
        height: auto;
    }

    .button {
        margin: 25px auto 50px !important;
    }
}