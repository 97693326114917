.web-service-page {
  * {
    max-width: 100vw;
  }

  p {
    width: 100%;
    margin: 14px 0px;
    padding: 0px 30px;
  }

  h2 {
    padding: 0px 30px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .button {
    width: 200px;
    outline: none;
    margin-top: 64px;
  }

  .flex {
    display: flex;
    justify-content: center;
  }

  .main-section {
    padding-bottom: 0px;
    .button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 150px;
    }
    #dark-background-button {
      margin-top: 20px;
      margin-left: 5px;
      margin-bottom: 0px;
    }
    p {
      margin: 14px auto;
    }
    .demo-form .demo-form-left-side {
      p {
        padding: 0px;
      }
      h3 {
        margin-left: 0px;
      }
    }
  }

  .main-section-text {
    height: 252px;
  }

  .contact-section-content {
    padding: 150px;
    padding-top: 50px;
    h2 {
      text-align: center;
    }
    p {
      margin: 28px auto;
    }
    .button {
      display: block;
      min-width: 200px;
      margin: 14px auto;
      margin-top: 50px;
    }
  }

  .seamless-section {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    .flex {
      flex-direction: row-reverse;
    }
    .cell {
      margin: 15px;
      width: calc(50% - 30px);
    }
    img {
      max-width: 100%;
    }
  }

  .innovative-section {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    h2 {
      margin-top: 0px;
      text-align: center;
    }
    .check-mark {
      color: white;
      background-color: #1414f0;
      text-align: center;
      border-radius: 50%;
      width: 40px;
      padding: 5px;
      margin: 40px auto;
      margin-left: 38%;
      margin-bottom: 40px;
    }
    .flex {
      flex-wrap: wrap;
      p {
        font-size: 14px;
      }
      strong {
        font-size: 16px;
      }
    }

    .cell {
      p {
        padding: 0px;
      }
      margin: 15px;
      width: calc(40% - 30px);
    }
  }

  .paper-free-section {
    max-width: 1200px;
    p {
      max-width: 1200px;
    }
    margin-top: 0px;
    padding: 50px;
    padding-top: 0px;
    margin: 150px auto;
    margin-top: 0px;
    margin-bottom: 75px;
    h2 {
      margin-top: 0px;
    }
    strong {
      font-size: 1.5em;
    }
    .customer-section-name-title {
      strong {
        font-size: 1em;
      }
      p {
        margin: 5px 0px 5px 0px;
      }
    }
    .button {
      display: block;
    }
    img {
      max-width: 550px;
      width: 100%;
      display: block;
      margin: 30px 30px;
      margin-bottom: 25px;
      margin-top: 0px;
    }
    p {
      margin: 14px auto;
    }
    p:nth-child(5n) {
      a {
        margin: 0px;
        margin-top: 50px;
      }
    }
  }

  .contact-section {
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 800px;
    max-width: none;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
    position: relative;
    padding: 0px;

    .button {
      margin: 80px auto;
      margin-top: 50px;
    }
  }

  .contact-section-content {
    padding-bottom: 0px;
  }
  @media (max-width: 1024px) {
    .main-section {
      padding-top: 0px;
      .button {
        margin-bottom: 95px;
      }
    }
    .contact-section-content {
      padding: 100px;
    }
  }

  @media (max-width: 768px) {
    section {
      margin: 75px auto;
    }

    .main-section {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    .main-section-text {
      height: 235px;
    }

    .seamless-section {
      padding-top: 0px;
      .flex {
        flex-direction: column-reverse;
      }

      p {
        padding: 0px;
      }

      h2 {
        padding: 0px;
        text-align: center;
      }
      .cell {
        width: 80%;
        margin: 30px auto;
        margin-top: 0px;
        img {
          width: 80%;
          margin: 0 auto;
          display: block;
        }
      }
      .cell:nth-child(1) {
        margin-top: 30px;
      }
    }

    .contact-section-content {
      padding: 50px 15px;

      padding-bottom: 0px;
    }

    .paper-free-section {
      h2 {
        margin-top: 30px;
      }
      .button {
        margin-left: 0px;
        margin-top: 50px;
      }
      .flex {
        flex-direction: column-reverse;
      }
      img {
        width: 80%;
      }
      a {
        display: block;
        margin: 30px auto;
        margin-top: 0px;
      }
    }
  }

  @media (max-width: 600px) {
    .main-section-text {
      height: 270px;
    }
    .innovative-section {
      .flex {
        flex-direction: column-reverse;
      }

      .cell {
        width: 80%;
        margin: 30px auto;
      }
      .check-mark {
        margin: 0 auto;
      }
    }

    .paper-free-section {
      strong {
        font-size: 1em;
      }
      img {
        width: 80%;
      }
    }
  }
  @media (max-width: 540px) {
    .main-section {
      padding-top: 0px;
      h1 {
        margin-top: 0;
      }
      p {
        margin-top: 0px;
      }
    }
  }
  @media (max-width: 414px) {
    .main-section {
      h1 {
        margin-top: 0;
      }
      p {
        margin-top: 0px;
      }
      .button {
        margin-top: 0px;
      }
    }
    .main-section-text {
      height: 340px;
    }

    section {
      padding: 0px;
    }

    .paper-free-section {
      .button {
        margin-top: 50px;
      }
      padding: 25px;
    }
  }

  @media (max-width: 375px) {
    .main-section-text {
      height: 330px;
    }
  }

  @media (max-width: 320px) {
    .main-section-text {
      height: 370px;
    }
  }

  @media (max-width: 280px) {
    .main-section {
      .button {
        margin-bottom: 80px;
      }
    }
    .main-section-text {
      height: 400px;
    }
    .paper-free-section {
      p {
        padding: 0px;
      }
      img {
        margin: 0px;
      }
    }
  }
}
