.digital-forms-page {
  * {
    max-width: 100vw;
  }

  p {
    margin: 14px 0;
  }

  h1 {
    strong {
      font-weight: 600;
    }
  }

  h2 {
    margin-top: 0px;
    text-align: center;
  }

  a {
    color: white;
    text-decoration: none;
  }
  .button {
    width: 200px;
    outline: none;
  }

  .flex {
    display: flex;
    justify-content: center;
  }

  .main-section {
    padding-bottom: 0px;

    h1 {
      margin-top: 0px;
    }

    p {
      margin: 14px auto;
    }
    button {
      display: block;
      width: 200px;
      margin: 0px auto;
      margin-bottom: 160px;
    }
    #dark-background-button {
      width: 150px;
      margin-top: 20px;
      margin-left: 5px;
      margin-bottom: 0px;
    }
    .demo-form .demo-form-left-side {
      p {
        padding: 0px;
      }
      h3 {
        margin-left: 0px;
      }
    }
  }

  .main-section-text {
    height: 252px;
  }

  .skip-paperwork-section {
    h2 {
      text-align: left;
    }
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    .flex {
      flex-direction: row-reverse;
    }

    .cell {
      margin: 0 30px;
      width: calc(50% - 30px);
    }

    img {
      width: 100%;
    }
  }

  .digital-percs {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;

    mark {
      background-color: #ffe7a6;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 2em;
    }
    div {
      padding: 15px;
    }
    .flex {
      padding-bottom: 0px;
      div {
        padding-bottom: 0px;
        p {
          margin-bottom: 0px;
        }
      }
    }
    .check-mark {
      color: white;
      background-color: #1414f0;
      text-align: center;
      border-radius: 50%;
      width: 40px;
      padding: 5px;
      margin: 0px auto;
      margin-left: 38%;
      margin-bottom: 40px;
    }
  }

  .customer-case {
    max-width: 1200px;
    p {
      max-width: 1200px;
    }
    .customer-case-name-title {
      p {
        margin: 5px 0px 5px 0px;
      }
      strong {
        font-size: 1em;
      }
    }
    padding: 0px 25px;
    margin: 150px auto;
    margin-top: 0px;
    img {
      margin: 0px 0px 30px 0px;
      display: block;
      width: 100%;
      max-width: 500px;
    }
    strong {
      font-size: 1.5em;
    }
    p {
      margin: 14px auto;
    }
    a {
      max-width: 840px;
    }
    .button {
      display: block;
      width: 200px;
      margin-top: 50px;
    }
  }

  .contact-section {
    max-width: none;
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 800px;
    padding: 75px 30px;
    padding-bottom: 0px;
    margin-bottom: 0;
    button {
      display: block;
      margin: 80px auto;
      margin-top: 50px;
    }
    p {
      margin: 14px auto;
    }
  }
}

@media (max-width: 1024px) {
  .digital-forms-page {
    .main-section {
      padding-top: 0px;
      .button {
        margin-bottom: 105px;
      }
    }
  }
}

@media (max-width: 768px) {
  .digital-forms-page {
    .main-section {
      margin-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      h1 {
        margin-top: 0px;
      }
      p {
        padding: 0px 30px;
      }
      .button {
        margin-bottom: 95px;
      }
    }
    .main-section-text {
      height: 235px;
    }
    p {
      margin: 14px auto;
    }

    .skip-paperwork-section {
      padding-top: 0px;
      .flex {
        flex-direction: column-reverse;
      }
      .cell {
        margin: 30px auto;
        width: 80%;
      }
      .cell:nth-last-child(1) {
        margin-top: 0px;
      }
      img {
        width: 80%;
        display: block;
        margin: 0 auto;
      }
    }

    .digital-percs {
      .check-mark {
        margin: 30px auto;
      }
      div {
        margin: 0 auto;
        width: 90%;
      }
      .flex {
        flex-direction: column;
      }
      h2 {
        margin-bottom: 1em;
      }
    }

    .customer-case {
      padding: 25px;
    }
  }
}

@media (max-width: 600px) {
  .digital-forms-page {
    .main-section {
      .button {
        margin-top: 0px;
      }
    }
    .main-section-text {
      height: 270px;
    }
    .digital-forms-page section {
      padding: 25px;
    }

    .skip-paperwork-section {
      .cell {
        width: 100%;
      }
    }

    .digital-percs {
      div {
        width: 100%;
      }
    }
    .customer-case {
      img {
        width: 100%;
      }
    }
    .contact-section {
      padding: 50px 30px 0px 30px;
    }
  }
}

@media (max-width: 540px) {
  .digital-forms-page {
    .main-section {
      padding-top: 0px;
    }
  }
}
@media (max-width: 420px) {
  .digital-forms-page {
    .main-section {
      padding: 0px 5px;
      .button {
        margin-top: 0px;
        margin-bottom: 95px;
      }
    }
    .main-section-text {
      height: 340px;
    }
    .skip-paperwork-section {
      margin-top: 0px;
      h2 {
        margin-left: 30px;
      }
      p {
        padding: 0px 30px;
      }
    }
    section {
      padding: 0px;
      margin: 75px auto;
    }
  }
}

@media (max-width: 375px) {
  .digital-forms-page {
    .main-section-text {
      height: 330px;
    }
  }
}

@media (max-width: 320px) {
  .digital-forms-page {
    .main-section-text {
      height: 370px;
      p {
        padding: 0 15px;
      }
    }
  }
}

@media (max-width: 280px) {
  .digital-forms-page {
    .main-section {
      .button {
        margin-bottom: 80px;
      }
    }
    .main-section-text {
      height: 400px;
    }
  }
}
