.about-us-page {
  padding-top: 50px;
  padding-bottom: 6rem;
  h1 {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
  }

  article {
    padding: 20px;
  }
}
