// Hide all web stuff that shouldn't be printed on paper
@media print {
    @page {
        size: auto;
    }
    html,
    body,
    .container,
    main,
    section,
    article {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        overflow: visible; // This might be needed to prevent page-break right in the middle of a text row.
    }

    html {
        body {
            .container {
                margin-top: 50px;
            }
            header {
                .nav-main,
                .toggle-nav,
                .visual-background-for-small-screen {
                    display: none;
                }
                #main-logo-outside-nav-link {
                    position: relative;
                    margin: 0 auto;
                    left: unset;
                }
            }
            .container main {
                h1 {
                    font-size: 22px;
                }
                h2 {
                    font-size: 20px;
                }
                h3 {
                    font-size: 14px;
                }
                p,
                .questions-page .related-question-list a {
                    font-size: 14px !important;
                }
                &.questions-page {
                    .breadcrumb {
                        font-size: 14px;
                    }

                    .related-question-list {
                        font-size: 14px;
                    }
                }
            }
            footer {
                padding-top: 0;
                padding-bottom: 0;
                border-top: 1px solid #4a4a4a;
                background: white;
                & > div {
                    padding: 0;
                    display: none;
                    color: #4a4a4a;
                    a {
                        color: #4a4a4a;
                    }
                    &:first-child {
                        display: block;
                    }
                }
                h4 {
                    margin-bottom: 0;
                }
                li {
                    font-size: 10px;
                }
            }
        }
    }
}
