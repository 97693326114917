#security-and-legislation {
  strong {
    display: block;
    padding-top: 2rem;
    padding-left: 1.7rem;
  }

  @media (max-width: 320px) {
    strong {
      padding-left: 0rem;
    }
  }
}
