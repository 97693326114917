.demo-form {
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  left: 50%;
  top: 50vh;
  width: 90%;
  margin-left: -45%;
  margin-top: -35vh;
  background-color: white;
  z-index: 110;
  .demo-form-left-side {
    width: 50%;
    padding: 8%;
    text-align: left;
    h3 {
      width: 90%;
      margin: 1em auto;
      font-size: 1.3em;
    }
    p {
      font-weight: 700;
      font-size: 1.05em;
    }
  }
  .demo-form-right-side {
    border-left: solid 1px;
    border-color: $orange;
    text-align: left;
    width: 50%;
    font-size: 0.6em;
    form {
      background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
      background-size: 100% 1150px;
      padding: 50px;
      width: 90%;
      height: 380px;
      margin: 100px auto;
      border-radius: 15px;
    }
    label {
      margin-top: 7px;
      display: block;
    }
    input,
    textarea {
      width: 100%;
      margin: 5px;
      border: solid 2px #1414f0;
      border-image-slice: 1;
      border-radius: 20px;
      background: white;
      outline: none;
      padding: 12px;
    }
    button {
      width: 150px;
      font-size: 1.2em;
      margin: 0px;
      margin-top: 20px;
      margin-left: 5px;
      padding: 10px 30px;
    }
  }
}

@media (min-width: 1024px) {
  .demo-form {
    left: 50%;
    top: 50vh;
    width: 1000px;
    margin-left: -500px;
    height: 75vh;
    margin-top: -35vh;
  }
}

@media (max-width: 1024px) {
  .demo-form {
    font-size: 0.8em;
    .demo-form-left-side {
      padding-top: 8%;
    }
    .demo-form-right-side {
      form {
        height: 400px;
      }
    }
  }
}

@media (max-width: 768px) {
  .demo-form {
    flex-direction: column;
    .demo-form-left-side {
      width: 70%;
      margin: 0 auto;
      padding-bottom: 0px;
    }
    .demo-form-right-side {
      margin-top: 0px;
      width: 100%;
      border: none;

      form {
        padding: 30px;
        height: 310px;
        width: 60%;
        margin-top: 0px;
        .button {
          display: block;
        }
      }
      #dark-background-button {
        width: 150px;
        display: block;
      }
    }
  }
}

@media (max-width: 600px) {
  .demo-form {
    position: fixed;
    left: 0;
    top: 0;
    margin-top: 0;
    width: 100%;
    margin-left: 0;
    height: 100vh;
    .demo-form-left-side {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 8%;
    }
  }
}

@media (max-width: 414px) {
  .demo-form {
    .demo-form-right-side {
      form {
        width: 90%;
      }
    }
  }
}

@media (max-width: 320px) {
  .demo-form {
    flex-direction: column;
    .demo-form-left-side {
      margin: 0 auto;
    }
    .demo-form-right-side {
      form {
        padding: 30px;
        height: 320px;
      }
    }
  }
}
