.userTermsPage {
  max-width: 840px;
  margin: 0 auto;
  padding: 1em;
  .main-section {
    h1,
    h2 {
      margin-top: 0px;
      align-self: start;
      text-align: left;
    }
  }
  ol {
    line-height: 18px;
    padding: 0;
    counter-reset: section;
    list-style-type: none;
    li {
      line-height: 18px;
      margin: 1em auto;
      &:before {
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        counter-increment: section;
        content: counters(section, ".") ". ";
      }
    }
    p:first-of-type {
      display: inline;
    }
    ol li {
      &:before {
        font-weight: normal;
        content: counters(section, ".") " ";
      }
      p {
        font-weight: normal;
      }
    }
    ol ol {
      //      list-style: lower-latin;
      li:before {
        content: "(" counter(section, lower-latin) ") ";
      }
    }
    p {
      line-height: 18px;
      //      line-height: 27px; // temp fix KA 
    }
  }
  article > ol > li > p:first-of-type {
    font-weight: bold;
  }
}
