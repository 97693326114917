header {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  font-family: "acumin-pro";
  font-style: normal;
  nav {
    flex: 2 0;
    font-size: 12px;

    .active {
      border-bottom: #1414f0 solid;
    }

    a,
    .language-switch-wrapper {
      color: #2e333d;
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .border-hover-effect {
    a {
      padding-bottom: 0.6em;
    }

    &:hover {
      a,
      .language-switch-wrapper {
        border-bottom: solid;
        border-bottom-color: $brand-color;
      }
    }
  }

  .logo {
    position: absolute;
    right: calc(50% - (25px / 2));
    top: 1rem;

    a {
      display: block;
      padding: 10px;

      svg path {
        transition: all ease 0.2s;
      }
    }

    svg path {
      fill: $brand-color;
    }

    svg:hover path {
      fill: $brand-color;
    }
  }

  #main-logo-li {
    svg {
      max-width: 90px;
    }
  }

  #main-logo-outside-nav-link {
    display: none;
    max-width: 90px;
  }

  margin: 0;
  .button {
    outline: none;
    max-width: 200px;
  }

  nav .header-ul {
    transition: 300ms;
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 1150px;
  }

  li {
    display: inline-flex;
    a:hover {
      text-decoration: none;
    }

    .arrow {
      display: none;
    }
  }

  #header-menu-button-li {
    .menu-contact-button {
      display: block;
      width: 200px;
      font-family: "acumin-pro";
      font-style: normal;
      font-weight: 600 !important;
      margin-top: 0;
      margin-bottom: 10px;
      margin-left: 30px;
      background-color: #2e333d;
      color: white;
      padding: 6px 12px;
      font-size: 0.92em;
      border: none !important;
      &:hover {
        transform: scale(1.04);
        opacity: 1;
      }
    }

    a:hover {
      opacity: 1;
    }
  }

  #navMainExpanded,
  #toggleNavUser {
    position: absolute;
    visibility: hidden;
  }

  .language-switch-wrapper {
    margin-top: 4px;
    transition: all ease 0.2s;
    padding-bottom: 4px;
    .language-switch {
      cursor: pointer;
      background-color: transparent;
      color: #2e333d;
      border: none;
      outline: none;
      font-size: 14.4px;
      line-height: 20px;
      font-family: "acumin-pro";
      height: 30px;
      appearance: none;

      option {
        background-color: #fff;
        color: #2e333d;
      }
    }

    @media (max-width: 600px) {
      height: 4rem;
      .language-switch {
        bottom: 1.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .button {
      outline: none;
      max-width: 180px;
    }
  }

  @media (max-width: 600px) {
    .button {
      outline: none;
      max-width: 170px;
    }
  }

  @media (pointer: fine) {
    @media (hover: hover), (min-width: 1367px) {
      @media (max-width: 1000px) {
        #header-menu-button-li {
          .menu-contact-button {
            padding: 6px 9px;
            font-size: 0.7em;
          }
        }
      }

      @media (max-width: 800px) {
        #header-menu-button-li {
          .menu-contact-button {
            padding: 6px 6px;
            font-size: 0.7em;
          }
        }
      }
    }
  }
}

@media (hover: none), (max-width: 1024px) {
  header {
    nav {
      .active {
        border-bottom: none;
        color: #1414f0;
      }
    }
  }

  .border-hover-effect:hover a {
    border: none;
    border-bottom: none !important;
  }

  #home-link-small-screen-li {
    display: block;
    margin-bottom: 10px;
  }

  .language-switch-li {
    position: absolute;
    top: 15px;
    right: 0;
    .language-switch-wrapper {
      border-bottom: none !important;
    }
  }

  header {
    flex-direction: column;

    #header-menu-button-li .menu-contact-button {
      margin: 0.5em;
      margin-left: 1em;
      font-size: 2em;
    }

    li {
      a {
        font-size: 2em;
      }
    }

    .visual-background-for-small-screen {
      background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
      transition: 300ms;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 79px;
      z-index: 3;

      &.hidden {
        display: none;
      }
    }

    #main-logo-li {
      top: 10px;
      left: 50%;
      margin-left: -50px;
      position: absolute;
      margin: margin 0 auto;
      display: none;
    }

    #main-logo-outside-nav-link {
      z-index: 5;
      top: 10px;
      left: 50%;
      margin-left: -50px;
      position: fixed;
      width: 100px;
      display: block;
    }

    .toggle-nav-main {
      position: fixed;
      top: 30px;
      z-index: 4;
      align-items: center;
      cursor: pointer;
      display: block;
      width: 25px;
      height: 17px;
      left: 1.2rem;
      border-top: 2px solid #000;
      border-bottom: 2px solid #000;

      &::before {
        content: "";
        display: block;
        position: relative;
        top: calc(50% - 1px);
        border-top: 2px solid #000;
      }
    }

    .toggle-nav-user {
      display: none;
    }

    @media (max-width: 1024px) {
      li {
        a {
          font-size: 1.5em;
        }
      }

      #header-menu-button-li {
        .menu-contact-button {
          width: 160px;
          font-size: 1.5em;
        }
      }
    }

    @media (max-width: 768px) {
      #header-menu-button-li {
        .menu-contact-button {
          width: 200px;
          font-size: 1.6em;
        }
      }
    }

    @media (max-width: 540px) {
      .header-ul {
        margin-top: 0px;
      }

      li {
        a {
          font-size: 1.5em;
        }
      }
    }

    @media (max-width: 414px) {
      li {
        a {
          font-size: 1.3em;
        }
      }

      #header-menu-button-li {
        .menu-contact-button {
          width: 140px;
          font-size: 1.2em;
        }
      }
    }
  }

  .nav-main {
    padding-top: 12vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    z-index: 2;
    top: -16em;
    height: 0;
    overflow: hidden;
    left: 0;
    width: 100%;
    transition: top 100ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    background: rgba(255, 255, 255, 1);

    .header-ul {
      margin-left: -5vw;
      background: none;
    }

    li {
      display: flex;
      a,
      .language-switch-wrapper {
        padding: 0.5em;
      }
    }

    &.expanded {
      overflow-y: auto;
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  .user-link {
    position: absolute;
    top: 0.9rem;
    height: 22px;
    right: 1.1rem;
    background: transparent url(/images/icon-user.svg) 50% 0 no-repeat;
    background-size: 22px auto;
    width: 22px;
    text-indent: -9999px;

    a:before {
      background: transparent url(/images/icon-user.svg) 50% 0 no-repeat;
    }
  }

  #toggleNavUser {
    &:checked ~ .nav-user {
      top: 0;
      height: 100%;
    }
  }
}

@media (pointer: fine) {
  @media (hover: hover), (min-width: 1367px) {
    @media (min-width: 1024px) {
      header {
        .nav-main .header-ul {
          height: 72px;
          display: flex;
          font-size: 1.2em;
          li {
            // Removed to prevent word break
            min-width: 100px;
            a {
              margin: 0px;
            }
          }
        }

        .nav-main .header-ul .invisible-li {
          width: 140px;
        }

        .nav-main {
          .header-ul {
            z-index: 5;
            position: fixed;
            width: 100vw;

            padding: 0px 50px;
            top: 0px;
            .text-li {
              justify-content: center;
              align-items: center;
            }

            #header-menu-button-li {
              width: 140px;
            }

            #main-logo-li {
              margin: 0 auto;
              margin-top: 5px;
            }
          }

          &-item__help a,
          &-item__user a {
            &:before {
              display: inline-block;
              margin-bottom: -0.3em;
              margin-right: 8px;
              content: "";
              height: 16px;
              width: 16px;
              background-size: 16px auto;
            }
          }

          &-item_home-link {
            display: block;
          }

          &-item__help a:before {
            background: transparent url(/images/icon-search.svg) 50% 0 no-repeat;
          }

          &-item__user {
            a:before {
              background: transparent url(/images/icon-user.svg) 50% 0 no-repeat;
            }
          }
        }

        .user-link {
          display: none;
        }
      }
    }
  }
}

@media (hover: hover), (max-height: 700px) {
  header {
    .nav-main .header-ul {
      #home-link-small-screen-li {
        display: none;
      }

      li {
        a {
          padding-top: 1vh;
          padding-bottom: 1vh;
        }
      }

      #service-link-li,
      #prices-link-li {
        .service-dropdown-ul,
        .prices-dropdown-ul {
          padding-top: 1vh;
          padding-bottom: 1vh;
          .dropdown-li {
            padding-top: 0;
            padding-bottom: 0;
            a {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
