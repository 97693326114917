.page-nyheter main {
  padding-top: 80px;
  width: 80%;
  max-width: 800px;
  margin: 3em auto;

  a {
    font-size: 16px;
  }

  article {
    margin-bottom: 4em;
  }

  ul {
    list-style-type: disc;
    margin: 2em;
    font-size: 16px;
    li {
      line-height: 1.7em;
    }
  }

  #contact-button {
    display: block;
    width: 200px;
    margin: 40px auto 0;
  }

  .news-date {
    margin: 0;
    color: $dark-grey;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
  }

  &.individual-news-article .news-date {
    font-size: 16px;
    margin-left: 1px;
  }

  &.individual-news-article .button {
    display: block;
    margin-top: 50px;
    margin-bottom: 150px;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    outline: none;
    text-decoration: none;
  }

  h1,
  h2,
  h3 {
    line-height: 1.25em;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1em;
  }

  h2 {
    margin: 0 0 0.4em;
    font-weight: normal;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.nyheter-list article a {
    color: $text-color;
    display: block;

    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
    }

    h2 {
      color: $brand-color;
    }

    &:hover h2 {
      text-decoration: underline;
    }

    &:hover p {
      color: $heading-color;
    }
  }

  @include for-phone-only {
    max-width: unset;
  }

  @media (max-width: 1024px) {
    padding: 0px;
  }

  @media (max-width: 320px) {
    padding: 0px;
    word-break: break-word;
    hyphens: auto;
  }
}
