body.overlay-active {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  display: none;

  &-show {
    display: flex;
    z-index: 5;
  }
}

.overlay-container {
  @media only screen and (min-width: 769px) {
    display: flex;
  }
  position: relative;
  background: white;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  margin: 0 0 0 0;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;

  @include for-phone-only {
    margin-top: constant(safe-area-inset-bottom); /* iOS 11.0 */
    margin-top: env(safe-area-inset-bottom); /* iOS 11.2 */
    margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
    width: 100%;
    max-height: 100vh;
  }

  h2 {
    font-size: 43px;
    font-weight: lighter;
    margin-top: 0;
    color: #20292b;
  }

  p {
    font-size: 18px;
    line-height: 1.66em;
    margin-top: 0;
  }
  .onboarding-steps {
    counter-reset: my-awesome-counter;
    list-style: none;
    padding-left: 55px;

    li {
      margin: 0 0 3rem 0;
      counter-increment: my-awesome-counter;
      position: relative;
      font-weight: bold;

      &:before {
        content: counter(my-awesome-counter);
        font-size: 0.9em;
        font-weight: normal;
        position: absolute;
        --size: 45px;
        left: calc(-1 * var(--size) - 13px);
        line-height: calc(var(--size) - 4px);
        width: var(--size);
        height: var(--size);
        top: -8px;
        border-radius: 50%;
        text-align: center;
        background-color: $brand-color;
        color: white;
      }
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 2.5em;
    width: 2.5em;
    padding: 1em;
    text-indent: -9999px;
    background: white url(/images/icon-close.svg) no-repeat center;
    background-size: 1.5em auto;
    border: 0;
    color: $brand-color;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      transition: all ease 0.2s;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    padding: 8%;
    align-self: flex-start;
    margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */

    @media only screen and (min-width: 769px) {
      border-left: transparent 1px solid;
      border-right: transparent 1px solid;

      &:first-of-type {
        border-right: 1px solid $orange;
      }
      &:last-of-type {
        border-left: 1px solid $orange;
        margin-left: -1px;
      }
    }
  }

  .customer {
    text-align: center;

    p {
      font-size: 15px;
    }

    &-img-container {
      display: inline-block;

      &::after {
        position: relative;
        display: block;
        content: "";
        height: 110px;
        width: 110px;
        margin-top: -116px;
        border-radius: 100%;
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.25) 50%,
          rgba(20, 20, 240, 0.5) 100%
        );
        // https://www.colorzilla.com/gradient-editor/#ffffff+50,1414f0+100&0+0,0.5+100
      }
    }

    strong {
      color: black;
      font-size: 16px;
    }

    img {
      margin: 0 auto;
      height: 110px;
      width: 110px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .popover {
    align-self: center;
    max-width: 270px;
    margin: 1rem 0 2rem;
  }

  .contactform {
    width: 100%;
    margin: auto 0;
    max-width: 100%;
    @include for-phone-only {
      padding-bottom: 3rem;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 320px;
      margin-bottom: 0.5em;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"],
    textarea {
      font-size: 15px;
      width: 100%;
      max-width: 320px;
      border: 1px solid #9b9b9b;
    }

    .button {
      //      margin-top: 1rem;
      min-width: 120px;
      padding: 10px 2.5em;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 2rem;
      }
    }

    p {
      font-size: 16px;
    }

    .terms {
      align-self: center;
      max-width: 75%;
      margin: 2em 0;
      font-size: 10px;
      font-style: italic;
    }

    &__full {
      input[type="text"],
      input[type="email"],
      input[type="number"],
      input[type="tel"] {
        background: white;
        border: 0;
        border-bottom: 1px solid #979797;
        border-radius: 0;
        padding: 5px 0;
      }
      textarea {
        border: 0;
        padding: 5px;
        background: $mid-grey;
      }

      .button {
        position: unset;
        right: unset;
        top: unset;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .call_form {
      .button {
        position: unset;
        margin-top: 8px;
        min-width: 100px;
      }
    }
  }
}
