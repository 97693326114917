.service-page {
  h2 {
    margin: 22px 0px;
  }
  .service-page-main-section {
    margin: 0px auto;
    margin-bottom: 0px;
    padding: 80px 50px;
    padding-bottom: 0px;
    p {
      padding: 0px 30px;
    }
  }

  #dark-background-button {
    width: 150px;
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 0px;
  }

  .slick-slider {
    width: 75%;
    margin: 0 auto;
  }

  .slider-section {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
    h2 {
      text-align: left;
    }
    #image-of-cissi {
      display: block;
      width: 75px;
      margin: 40px auto;
      border-radius: 50%;
    }
    .customer-quote {
      p {
        font-size: 24px;
      }
    }

    .name-and-title-field {
      text-align: center;
    }

    #screen-01 #screen-02 {
      width: 100%;
    }
    .slick-slide img {
      width: 100%;
      margin: 0 auto;
      max-width: 320px;
    }
    .slick-prev {
      left: -50px;
    }
    .slick-next {
      right: -50px;
    }

    .cell {
      margin: 20px;
      width: calc(50% - 20px);
    }

    .button {
      width: 200px;
      margin-top: 35px;
      margin-left: -10px;
    }
  }

  .upload-files-slider {
    flex-direction: row-reverse;
  }

  .efficiency-section {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;

    #efficiency-illustration {
      max-width: 800px;
      display: block;
      width: 100%;
    }
    .cell {
      width: calc(50% - 30px);
      margin: 0px 30px;
    }
  }

  .map-of-service-section {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;

    h2 {
      margin-top: 0px;
    }
    #map-of-service-illustration {
      width: 100%;
      max-width: 800px;
      display: block;
      margin: 0 auto;
    }
    p {
      margin-bottom: 100px;
    }
  }

  .list-of-egreements-services {
    h2 {
      margin-top: 0;
      text-align: center;
    }
    .scroll-margin-top {
      scroll-margin-top: 5em;
    }
    padding: 30px;
    padding-top: 0;
    table {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .service-page {
    .slider-section {
      #screen-01 #screen-02 {
        width: 100%;
      }
      .cell {
        width: calc(45% - 30px);
      }
      .slick-prev {
        left: -90px;
      }
      .slick-next {
        right: -90px;
      }
    }
    .service-page-main-section {
      padding: 5rem 5% 6rem;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .efficiency-section {
      flex-direction: column-reverse;
      align-items: center;

      .cell {
        width: calc(80% - 30px);
        #efficiency-illustration {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    .slider-section {
      button {
        width: 50%;
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 900px) {
  .service-page {
    h2 {
      margin: 22px 0px;
    }
    .slider-section {
      .cell {
        width: 60%;
        margin: 30px auto;
      }

      .text {
        width: 100%;
      }
      p {
        margin: 20px auto;
      }
      .button {
        display: block;
        margin: 0px auto;
        margin-top: 50px;
        width: 200px;
      }
      flex-direction: column-reverse;
      justify-content: center;
      #screen-01 #screen-02 {
        width: 100%;
      }
      .slick-prev {
        left: -100px;
      }
      .slick-next {
        right: -100px;
      }
    }
    .efficiency-section {
      .cell {
        margin: 0px;
        width: 100%;
        #efficiency-illustration {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .service-page {
    .list-of-egreements-services {
      h2 {
        margin-bottom: 100px;
      }
    }
  }
}

@media (max-width: 650px) {
  .service-page {
    .slider-section {
      .customer-quote {
        p {
          font-size: 16px;
        }
      }
    }
    .slick-slider {
      width: 100%;
      margin: 0 auto;
    }
    .text {
      width: 100%;
      margin-top: 40px;
    }
  }

  @media (max-width: 444px) {
    .service-page {
      .slider-section {
        .phone-cell {
          width: 70%;
        }
        .slick-prev {
          display: none !important;
        }
        .slick-next {
          display: none !important;
        }
        .slider-section {
          button {
            width: 70%;
          }
        }
      }
    }
  }

  @media (max-width: 315px) {
    .table-functions {
      th {
        font-size: 0.75em;
      }
    }
  }
}
