// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "acumin-pro", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: #4a4a4a !default;

/// Heading color
/// @type Color
$heading-color: #20292b !default;

/// Main brand color
/// @type Color
$brand-color: #1414f0 !default;

/// Light grey
/// @type Color
$light-grey: #f2f2f2 !default;

/// Medium grey
/// @type Color
$mid-grey: #eaeaea !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Orange
/// @type Color
$orange: #fa5a21 !default;

/// Green
/// @type Color
$green: #3cb152 !default;

/// Container's maximum width
/// @type Length
$max-width: 1400px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
) !default;

// for margins, padding etc...
$spacing-unit: 10px;

// p, li, ol , ul, ...  
$standard-font-size: 16px;

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;
