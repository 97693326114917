.arrow {
  border: solid #1414f0;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: 20px;
  height: 20px;
  margin-top: 2.2em;
}

.big {
  width: 20px;
  height: 20px;
  border-width: 0 3px 3px 0;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@media (hover: hover), (min-width: 1367px) {
  .arrow {
    margin-top: 3px;
    margin-left: 7px;
    margin-bottom: 12px;
    width: 8px;
    height: 8px;
    border-width: 0 2px 2px 0;
  }

  .big {
    width: 15px;
    height: 15px;
    border-width: 0 3px 3px 0;
  }
}

@media (hover: none), (max-width: 1024px) {
  .arrow {
    display: none;
  }

  .big {
    display: block;
  }
}
