.partner-section {
  margin-top: 150px;
  h2 {
    margin-top: 0px;
    text-align: center;
  }
  & > p,
  h2,
  h3 {
    width: calc(100% - 10vw);
    max-width: 840px;
  }
  padding: 0px;
  a {
    text-decoration: none;
    font-weight: 800;
    font-size: 1.2em;
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
  ul {
    width: 100%;
    max-width: 840px;
    font-size: 16px;
    list-style-type: disc;
    margin: 0.5em auto;
    padding-left: 2em;
  }
  .case-card-wrapper {
    h2 {
      text-align: left;
      margin-top: 35px;
    }
  }

  .button {
    margin-top: 35px;
  }
}

@media (max-width: 768px) {
  .partner-section {
    margin-top: 100px;
  }
}
