.old-faq-page {
  padding: 30px;
  max-width: 840px;
  margin: 0px auto;

  h1 {
    text-align: left;
  }
  .h2-faq-page {
    align-self: auto;
  }
  li {
    list-style: disc;
    margin-left: 60px;
  }
  span {
    color: blue;
  }
  span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .main-section {
    margin: 0 auto;
    padding: 0px 0px 20px 0px;
  }

  article {
    section {
      padding: 0px;
    }
    .anchor {
      display: block;
      position: relative;
      top: -50px;
      visibility: hidden;
    }
  }
  @media (max-width: 414px) {
    h1 {
      padding-left: 10px;
      padding-right: 10px;
    }
    article {
      padding-left: 15px;
      padding-right: 15px;
    }
    padding: 0px;
    li {
      margin-left: 10px;
    }
  }
  @media (max-width: 320px) {
    h3 {
      font-size: 1.1em;
      font-weight: 500;
    }
    article {
      padding-left: 15px;
      padding-right: 15px;
    }
    padding: 0px;
  }
}
