.cases-slider-wrapper {
  margin-top: $spacing-unit * 10;
  margin-bottom: $spacing-unit * 10;
  max-width: 840px;
  margin: 0px auto;
  margin-top: 100px;

  .hr-top {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .hr-bottom {
    margin: 0 auto;
    margin-top: 5px;
  }

  .cases-slider {
    height: 100px;
    max-width: 80%;
    margin: 0 auto;
    img {
      height: 60px;
      width: auto;
      margin: 0 20px;
    }

    img[src*="stockholmshem"] {
      margin-top: 20px;
      margin-left: 70px;
      width: 175px;
      height: auto;
    }

    img[src*="toyota"] {
      margin-top: 20px;
      margin-left: 70px;
      width: 175px;
      height: auto;
    }
    img[src*="akersberga"] {
      margin-top: 15px;
      margin-left: 50px;
      width: 150px;
      height: auto;
    }
    img[src*="telia-company-logo"] {
      margin-top: 15px;
      margin-left: 50px;
      width: 150px;
      height: auto;
    }
  }
}

@media (max-width: 900px) {
  .cases-slider-wrapper {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .cases-slider-wrapper {
    max-width: 600px;
    .cases-slider {
      img[src*="stockholmshem"] {
        margin-top: 25px;
        margin-left: 20px;
        width: 100px;
        height: auto;
      }

      img[src*="toyota"] {
        margin-left: 0px;
        width: 150px;
        height: auto;
      }
      img[src*="akersberga"] {
        margin-top: 15px;
        margin-left: 30px;
        width: 150px;
        height: auto;
      }
      img[src*="telia-company-logo"] {
        margin-top: 15px;
        margin-left: 30px;
        width: 150px;
        height: auto;
      }
    }
  }
}

@media (max-width: 650px) {
  .cases-slider-wrapper {
    max-width: 450px;
    .cases-slider {
      max-width: 65%;
      img {
        height: 45px;
      }
    }
  }
}

@media (max-width: 550px) {
  .cases-slider-wrapper {
    .cases-slider {
      img {
        height: 30px;
      }

      img[src*="stockholmshem"] {
        margin-top: 10px;
        margin-left: 20px;
        width: 100px;
        height: auto;
      }

      img[src*="toyota"] {
        margin-top: 5px;
        margin-left: 0px;
        width: 150px;
        height: auto;
      }
      img[src*="akersberga"] {
        margin-top: 5px;
        margin-left: 0px;
        width: 130px;
        height: auto;
      }
      img[src*="telia-company-logo"] {
        margin-left: 0px;
        width: 130px;
        height: auto;
      }
    }
  }
}

@media (max-width: 500px) {
  .cases-slider-wrapper {
    max-width: 320px;
    .cases-slider {
      height: 70px;
      img {
        height: 30px;
      }
      img[src*="stockholmshem"] {
        margin-top: 10px;
        margin-left: 20px;
        width: 50px;
        height: auto;
      }

      img[src*="toyota"] {
        margin-top: 7px;
        margin-left: 15px;
        width: 50px;
        height: auto;
      }
      img[src*="akersberga"] {
        margin-left: 20px;
        margin-top: 8px;
        width: 80px;
        height: auto;
      }
      img[src*="telia-company-logo"] {
        margin-left: 10px;
        margin-top: 7px;
        width: 90px;
        height: auto;
      }
    }
  }
}
@media (max-width: 400px) {
  .cases-slider-wrapper {
    max-width: 230px;
    .cases-slider {
      height: 60px;
      img {
        height: 20px;
      }
      img[src*="stockholmshem"] {
        margin-top: 3px;
        margin-left: 20px;
        width: 50px;
        height: auto;
      }

      img[src*="toyota"] {
        margin-top: 3px;
        margin-left: 15px;
        width: 50px;
        height: auto;
      }
      img[src*="akersberga"] {
        margin-left: 12px;
        margin-top: 4px;
        width: 60px;
        height: auto;
      }
      img[src*="telia-company-logo"] {
        margin-left: 10px;
        margin-top: 5px;
        width: 60px;
        height: auto;
      }
    }
  }
}
