#individual-case-huddinge-samhallsfastigheter {
  img[alt^="Huddinge Samhällsfastigheters"] {
    max-width: 350px;
    width: 100%;
    margin-bottom: 60px;
  }

  img[alt^="Mathias Holm"] {
    margin-top: 60px;
  }

  h2 {
    margin-top: 60px;
  }

  a {
    color: #1414f0;
  }
}
