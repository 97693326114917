#guide-for-real-estate {
  img {
    margin-top: 30px;
    margin-bottom: -20px;
  }

  h2 {
    margin-top: 60px;
  }

  ul {
    list-style-type: disc;
    margin: 2em;
    font-size: 16px;
    li {
      line-height: 1.7em;
    }
  }

  a {
    display: block;
    width: 200px;
    margin: 60px auto 60px auto;
    align-self: center;
    font-size: 16px;
    padding: 15px;
    color: white;
    border: 0;
    border-radius: 25px; // Maybe change this back to  3px? and create a class for rounded corners. But as it seems not this is standard
    clear: both;
    text-align: center;
    transition: all ease 0.2s;
    background: #1414f0;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      cursor: pointer;
      opacity: 0.9;
      transition: all ease 0.2s;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:active {
      outline: none;
      text-decoration: none;
    }
  }

  .border-radius {
    border-radius: 25px;
  }
}
