#individual-case-helsingborgshem {
  h2 {
    margin-top: 60px;
  }

  a {
    display: inline;
    color: #1414f0;
  }

  a:hover {
    text-decoration: underline;
  }

  img[alt="Richard Mauritsson"] {
    margin-top: 60px;
    width: 200px;
  }

  img[alt="Kristin Berg"] {
    margin-top: 60px;
    width: 200px;
  }

  em {
    strong {
      display: block;
      margin-top: -10px;
      margin-bottom: 30px;
    }
  }
}
