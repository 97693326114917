#individual-case-sigillet-fastighets-ab {
  h2 {
    margin-top: 60px;
  }

  img[alt="Adam Nilsson"] {
    margin-top: 60px;
  }

  em {
    font-size: 1em;
  }
}
