.page-generic,
.page-cases,
.page-partners,
.page-omoss,
.page-support,
.page-faq-fragor-och-svar {
  // Page body class
  main {
    width: 80%;
    max-width: 800px;
    margin: 3em auto;
    padding: 1em;

    .breadcrumb {
      font-size: 16px;
      .back-to-service-arrow {
        margin-right: 5px;
        text-decoration: none;
      }

      a {
        color: #4a4a4a;
      }
    }

    article {
      margin: 2.5em 0;

      ul {
        list-style: initial;
      }
      ul,
      ol {
        margin: 1em 0;
        padding-left: 40px;
      }
    }

    #identifiering-vid-avtal {
      width: 50%;
    }

    #ip-restriktioner {
      width: 80px;
    }

    #lagring {
      width: 80px;
    }

    #folj-status {
      margin: 0;
      margin-top: 30px;
    }

    .img-border {
      border: grey solid 2px;
    }

    .large-img {
      margin: 0 auto;
      display: block;
      width: 100%;
      margin-top: 30px;
    }
    .medium-img {
      margin: 0 auto;
      display: block;
      width: 70%;
      margin-top: 30px;
    }
    .small-img {
      margin: 0 auto;
      display: block;
      width: 25%;
      margin-top: 30px;
    }

    @media screen and (max-width: 900px) {
      #identifiering-vid-avtal {
        width: 80%;
      }
    }

    @media screen and (max-width: 414px) {
      .medium-img {
        width: 100%;
      }
      .small-img {
        width: 50%;
      }
    }

    h2 {
      margin: 0 0 0.4em;
    }

    h1,
    h2,
    h3 {
      line-height: 1.25em;
    }

    img {
      width: 200px;
      height: auto;
    }

    a:hover img {
      opacity: 0.9;
    }

    @include for-phone-only {
      width: unset;
      max-width: 100%;
      margin: 2em 0 0;
    }
  }
}

.page-faq article ul {
  margin-bottom: 20vh;
}

.page-faq-fragor-och-svar {
  main h2,
  h3 {
    margin-top: 3em;
    font-weight: 500;
  }
  h2 + h3 {
    margin-top: unset;
  }
}
