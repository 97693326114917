.request-free-account-form {
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  width: 50%;
  min-width: 320px;
  background-color: white;
  z-index: 110;
  .full-width {
    width: 100%;
    padding: 8%;
    text-align: center;
    h2 {
      font-size: 70px;
      font-weight: bold;
      margin: 0;
      color: #1315ef;
    }
    h3 {
      font-size: 30px;
      color: #9b9b9b;
      margin-top: 0;
    }
    p {
      font-size: 19px;
      margin: 0 auto;
      width: unset !important;
      &.permission {
        font-size: 12px;
        color: #9b9b9b;
        a {
          color: #9b9b9b;
          text-decoration: underline;
        }
      }
    }
    form {
      width: 90%;
      margin: 5vh auto;
      border-radius: 15px;
    }
    label {
      margin-top: 7px;
      display: block;
    }
    input,
    textarea {
      font-size: 16px;
      width: 100%;
      display: block;
      margin: 5px auto;
      border: solid 2px #1414f0;
      border-image-slice: 1;
      border-radius: 25px;
      background: white;
      outline: none;
      padding: 12px;
      max-width: 520px;
    }
    button {
      width: 150px;
      font-size: 1.2em;
      margin: 0px;
      margin-top: 20px;
      margin-left: 5px;
      padding: 10px 30px;
    }
  }
}

.thank-you-message {
  padding: 40px;
  height: 70vh;
  position: fixed;
  width: 35%;
  min-width: 280px;
  background-color: white;
  z-index: 110;
  h2 {
    color: #1414f0;
  }
  #requestAccountForm {
    display: block;
    width: 200px;
    position: absolute;
    left: calc(50% - 100px);
    top: calc(70%);
  }
  a {
    display: block;
    width: 200px;
    position: absolute;
    left: calc(50% - 100px);
    top: calc(93%);
    color: #4a4a4a;
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  .demo-form {
    left: 50%;
    top: 50vh;
    width: 1000px;
    margin-left: -500px;
    height: 70vh;
    margin-top: -35vh;
  }
}

@media (max-width: 768px) {
  .request-free-account-form .full-width {
    flex-direction: column;
    form {
      width: 100%;
      padding-right: 2vw;
      padding-left: 2vw;
      margin-bottom: unset;
      input {
        margin: 0;
      }
    }
  }
}

@media (max-width: 414px) {
  .demo-form {
    .demo-form-right-side {
      form {
        width: 90%;
      }
    }
  }
}

@media (max-width: 320px) {
  .demo-form {
    flex-direction: column;
    .demo-form-left-side {
      margin: 0 auto;
    }
    .demo-form-right-side {
      form {
        padding: 30px;
        height: 320px;
      }
    }
  }
}
