.nav-main {
  .header-ul {
    .prices-dropdown-ul {
      padding: 0.8em;
      background: white;
      width: 250px;
      display: none;
      flex-direction: column;
      position: absolute;
      top: 71px;
      border-radius: 0px 0px 10px 10px;
      box-shadow: 5px 5px 5px #acacac;
      margin-left: 10.4em;
      .dropdown-li {
        padding: 0.5em;
        width: 100%;
        height: 43px;
      }
      // media query is a Safari hack
      @media not all and (min-resolution: 0.001dpcm) {
        margin-left: 75px;
      }
    }
    #prices-link:hover {
      border-bottom: solid $brand-color;
    }
    #prices-link-li:hover {
      background-color: white;
    }

    @media (hover: none), (max-width: 1024px) {
      .service-dropdown-ul {
        .dropdown-li {
          height: auto !important;
        }
      }
      #prices-link:hover {
        border-bottom: none;
      }
      #prices-link-li:hover {
        background-color: transparent;
      }
      .active a {
        border-bottom: solid $brand-color;
      }
      li {
        a:active {
          border: none;
        }
      }
      .prices-dropdown-ul {
        width: 35em;
        display: flex;
        position: relative;
        left: -100px;
        margin-left: 0px;
        top: 40px;
        box-shadow: none;
        background: transparent;
        margin-bottom: 30px;

        .dropdown-li {
          border: none;
        }
      }
    }
  }

  .header-ul {
    // fel
    @media (hover: none), (max-width: 1024px) {
      #prices-link {
        display: block;
      }
      #prices-link-li {
        #prices-link {
          margin-top: 10px;
          width: 120px;
          background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
          background-size: 100% 1150px;
          border-radius: 10px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        display: flex;
        flex-direction: column;
      }
      .prices-dropdown-ul {
        width: 285px;
        font-size: 0.8em;
//        margin-left: 15px;
        border-radius: 10px;
        border-top-left-radius: 0px;
        background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
        background-size: 100% 1150px;
        position: static;
        margin-bottom: 0px;
        padding: 0px;
        .dropdown-li {
          border: none;
        }
      }

      #main-logo-li {
        margin-right: 0;
      }

      .invisible-li {
        width: 0px;
      }
      padding: 0 10px;

      font-size: 0.88em;
    }
  }

  @media (hover: none), (max-width: 1024px) {
    @media (max-width: 1024px) {
      .header-ul {
        #prices-link-li {
          #prices-link {
            width: 85px;
          }
          .prices-dropdown-ul {
            width: 225px;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .header-ul {
        #prices-link-li {
          #prices-link {
            width: 100px;
          }
          .prices-dropdown-ul {
            width: 225px;
          }
        }
      }
    }
    @media (max-width: 540px) {
      .header-ul {
        #prices-link-li {
          #prices-link {
            width: 85px;
          }
          .prices-dropdown-ul {
            width: 225px;
          }
        }
      }
    }
    @media (max-width: 375px) {
      .header-ul {
        #prices-link-li {
          #prices-link {
            width: 70px;
          }
          .prices-dropdown-ul {
            width: 200px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    @media (hover: none), (max-width: 1024px) {
      .header-ul {
        #prices-link {
          max-height: 37px;
        }
      }
    }
    header {
      #header-menu-button-li {
        .menu-contact-button {
          font-size: 1.3em;
        }
      }
    }
  }
}
