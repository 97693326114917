.faq-search-component {
  position: relative;
  h2 {
    width: 75%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    font-size: 0.8em;
    color: #1414f0;
  }

  p {
    font-size: 0.6em;
  }

  #paragraph-under-search-bar {
    width: 97%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8em;
  }

  span {
    font-size: 0.6em;
    font-weight: 800;
  }

  .searchSnippet {
    padding: 6px 11px;
    margin: 4px 0px;
    border-radius: 10px;
    // background-color: #dadef2;
    background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
    background-size: 100% 485px;
  }

  input {
    display: block;
    width: 97%;
    max-width: 640px;
    margin: 0 auto;
    border-radius: 50px;
    border: none;
    padding: 12px 25px;
    outline: none;
    margin-bottom: 4em;
    margin-top: 2em;
  }

  #searchResults {
    position: relative;
    top: -80px;
    margin: 0 auto;
    width: 97%;
    max-width: 640px;
    max-height: 30vh;
    overflow-y: scroll;
    cursor: pointer;
  }

  h3 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
