.id-card-section {
  padding: 0px;
  .orange {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .flex {
    display: flex;
    p:nth-child(1) {
      width: 5%;
      margin-right: 5px;
    }
  }

  & > {
    p,
    h2,
    h3 {
      text-align: center;
      width: calc(100% - 10vw);
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    text-align: left;
    margin: 1em auto;
    font-size: 14px;
  }


  .ID-card-container {
    max-width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px auto;
    margin-top: 3em;

    .ID-card {
      position: relative;
      max-width: 360px;
      background-color: #ffffff;
      width: calc(25% - 40px);
      padding: 20px;
      text-align: center;
      margin: 20px;
      box-shadow: 0px 0px $spacing-unit #8891a0;
      transition: 300ms;
      &:hover {
        z-index: 2;
        transform: scale(1.04);
      }

      .ID-card-upper-half {
        img {
          height: 150px;
        }
        min-height: 320px;
        h3 {
          font-weight: 900;
          font-size: 16px;
        }
      }
    }
  }
  .button {
    display: block;
    padding: 10px 35px;
    width: 160px;
    margin: 0 auto;
    margin-bottom: 25px;
    outline: none;
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
  .lower-half {
    padding: $spacing-unit * 2;
    margin-bottom: 20px;
    min-height: 200px;
    p {
      text-align: left;
      margin: 0.8em 0;
    }
  }
}

@media (max-width: 1400px) {
  .id-card-section {
    .ID-card-container {
      max-width: 840px;
      padding-left: 0px;
      padding-right: 0px;
      .ID-card {
        width: calc(50% - 40px);
      }
    }
    .lower-half {
      min-height: 180px;
    }
  }
}

@media (max-width: 1024px) {
  .id-card-section {
    margin: 0 auto;
    max-width: 840px;
    .ID-card-container {
      padding-left: 0px;
      padding-right: 0px;
      .ID-card {
        width: calc(50% - 40px);
      }
    }
    .lower-half {
      min-height: 200px;
    }
  }
}

@media (max-width: 768px) {
  .id-card-section {
    .ID-card-container {
      padding-left: 0px;
      padding-right: 0px;
      .ID-card {
        width: calc(50% - 40px);
      }
    }
    .lower-half {
      min-height: 240px;
    }
  }
}

@media (max-width: 600px) {
  .id-card-section {
    .ID-card-container {
      hr {
        margin-top: 60px;
      }
      .ID-card {
        width: calc(100% - 10vw);
        .ID-card-upper-half {
          min-height: 200px;
        }
      }
    }
    .lower-half {
      min-height: 200px;
    }
  }
}

@media (max-width: 420px) {
  .id-card-section {
    .lower-half {
      padding: 0px;
      padding-top: 20px;
    }

    .button {
      width: 165px;
    }
  }
}
