.individual-news-article {
  .individual-news-article-element {
    h2 {
      margin-top: 60px;
    }

    img {
      margin-top: 60px;
      display: block;
    }
  }
}
