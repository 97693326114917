.prices {
    padding-top: 80px;
  
    h1 {
      text-align: center;
      margin: 0 auto;
      max-width: 90vw;
    }
    .main-description {
      width: 90vw;
      max-width: 880px;
      text-align: center;
    }
  
    .flex {
      display: flex;
      flex-direction: row;
      max-width: 95%;
      flex-wrap: wrap;
      justify-content: center;
      margin: 150px auto;
      margin-top: 75px;
    }
  
    h3 {
      font-weight: 900;
      margin-bottom: 0.5em;
    }
  
    hr {
      border-bottom-color: #fa5a21;
      margin: 2em auto;
    }
  
    .cell {
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 300px;
      background-color: #ffffff;
      width: calc(25% - 4vw);
      margin: 2vw;
      padding: 20px 10px 40px;
      text-align: center;
      box-shadow: 0px 0px $spacing-unit #8891a0;
      transition: 300ms;
      &:hover {
        z-index: 2;
        transform: scale(1.04);
      }
    }
    ul {
      margin-bottom: 6vh;
    }
    li {
      text-align: left;
      font-size: 14px;
      margin: 0.8em 0;
      padding: 5px 15px;
    }
    .offer {
      font-weight: 700;
      font-size: 1.4em;
      margin: 5px 0;
      color: black;
    }
  
    .description {
      min-height: 80px;
    }
  
    .button {
      width: 200px;
      max-width: 100%;
      outline: none;
    }
  
    @media (max-width: 1124px) {
      .cell {
        width: calc(50% - 60px);
        max-width: 360px;
      }
    }
  
    @media (max-width: 650px) {
      .cell {
        display: block;
        padding: 2vh 2vw 5vh;
        width: 80%;
      }
    }
    @media (max-width: 414px) {
      .cell {
        width: 100%;
      }
    }
  
    @media (max-width: 320px) {
      .cell {
        .button {
          width: 150px;
        }
      }
  
      .cell {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    @media (max-width: 280px) {
      .cell {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  