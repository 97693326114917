.pink-background-faq {
  background: linear-gradient(165.35deg, #f2f2f2 -2.73%, #ffe4e8 171.06%) no-repeat;
  background-size: 100% calc(7vw + 14em);
}

@media (max-width: map-get($breakpoints, 'large')) {
    .pink-background-faq {
      background-size: 100% calc(15vh + 8.4em);
  }
}

.new-faq-page {
  max-width: 840px;
  margin: 0px auto;
  padding-bottom: 80px;
  padding-top: 80px;
  .h2-faq-page,
  .button-h3 {
    opacity: 0.85;
    transition: all 0.2s ease;
    &:hover {
      opacity: 1;
    }
  }

  h1 {
    text-align: center;
  }

  h2 {
    font-size: 1.2em;
    display: flex;
  }

  h3 {
    font-size: 1em;
    display: flex;
  }

  .headline-search-snipper:hover {
    text-decoration: underline;
  }

  .h2-faq-page {
    border: solid 1px #acacac;
    align-self: auto;
    border-radius: 3px;
    padding: 10px;
    margin: 2px;
    cursor: pointer;
  }

  .button-h3 {
    border-bottom: solid 1px #acacac;
    margin: 0px 0px 0px 50px;
    width: calc(100% - 50px);
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .plus-sign {
    display: inline-block;
    width: 30px;
    color: black;
    margin-right: 0px;
    margin-left: 10px;
  }

  .plus-sign:hover {
    text-decoration: none;
  }

  .section-content {
    display: none;
  }

  .small-section-content {
    // display: none;
    margin-left: 50px;
    width: calc(100% - 50px);
    padding-left: 10px;
  }

  .main-section {
    margin: 0 auto;
    padding-top: 80px;
  }

  section {
    padding: 0px;
  }

  .anchor {
    display: block;
    position: relative;
    top: -300px;
    visibility: hidden;
  }

  .api-button-wrapper {
    .api-button {
      display: block;
      width: 200px;
      font-family: "acumin-pro";
      font-style: normal;
      font-weight: 600 !important;
      margin-top: 0;
      margin-bottom: 10px;
      background-color: #2e333d;
      color: white;
      padding: 6px 12px;
      font-size: 0.92em;
      border: none !important;
      border-radius: 15px;
      text-decoration: none;
      &:hover {
        transform: scale(1.04);
        opacity: 1;
      }
    }

    a:hover {
      opacity: 1;
    }
  }

  .contact-info {
    padding-top: 3em;
    h2 {
      font-size: 2em;
    }

    max-width: 855px;
    margin: 0 auto;

    p {
      margin: 0 auto;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 0px;
    span:hover {
      text-decoration: none;
    }

    span: active {
      text-decoration: underline;
    }

    .main-section {
      margin: 0 auto;
      padding-top: 0;
    }

    padding-left: 30px;
    padding-right: 30px;
    li {
      list-style: disc;
    }
  }

  @media (max-width: 540px) {
    h2 {
      width: 100%;
    }

    .h2-faq-page {
      width: 100%;
    }

    #paragraph-under-search-bar {
      width: 100%;
    }
  }

  @media (max-width: 414px) {
    padding-left: 30px;
    padding-right: 30px;
    li {
      list-style: disc;
      margin-left: 10px;
    }

    h2 {
      font-size: 1em;
      font-weight: 800;
    }

    .button-h3 {
      font-size: 1em;
      font-weight: 600;
      width: 100%;
      margin: 0px;
    }

    .small-section-content {
      width: 100%;
      margin: 0px;
    }

    .plus-sign {
      margin-right: 10px;
      margin-left: 0px;
    }

    input {
      width: 97%;
    }
  }
}
