hr {
    margin: 0 auto;
    border-width: 0;
    width: 80%;
    max-width: 980px;
    border-bottom: 1px solid $text-color;

    &.orange {
        border-bottom-color: $orange;
    }

    &.blue {
        border-bottom-color: $brand-color;
    }

    &.hr-below-slider {
        margin-top: 80px;
    }

    @include for-phone-only {
        width: 100%;
    }
}
