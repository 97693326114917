#individual-news-real-estate-corona {
  ul {
    list-style-type: disc;
    margin: 2em;
    font-size: 16px;
    li {
      line-height: 1.7em;
    }
  }

  h2 {
    margin-top: 60px;
  }

  img {
    margin-bottom: 25px;
  }
}
