.popover {
  position: relative;
  font-size: 18px;
  line-height: 1.33em;

  &-body {
    padding: 1rem 2rem;

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;

    &:before,
    &:after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0 1rem 1rem 1rem;
    }

    &:before {
      top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.25);
    }
    &:after {
      top: 1px;
      border-bottom-color: #fff;
    }
  }

  &-bottom .popover-arrow {
    top: calc((1rem + 1px) * -1);
    left: calc(50% - 16px);
  }

  &-brand-color {
    color: white;
    background: $brand-color;

    .popover-arrow {
      top: calc(1rem * -1);
    }
    .popover-arrow:after {
      border-bottom-color: $brand-color;
    }
  }
}
