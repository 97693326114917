.page-partners {
  .partners-logos {
    margin: 0 auto;
    width: 100%;
    max-width: 716px;
    margin-bottom: 3em;

    div img {
      height: 50px;
      width: auto;
      margin: 0 20px;
    }

    .slick-prev {
      left: -7%;
    }

    .slick-next {
      right: -7%;
    }
    @include for-phone-only {
      width: 80%;

      div img {
        height: 30px;
        margin: 0 10px;
      }

      .slick-prev {
        left: -13%;
      }

      .slick-next {
        right: -13%;
      }
    }
  }
}
